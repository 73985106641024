import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import Loader from '../../elements/Loader';
import { Dropdown } from 'react-bootstrap';
import noImage from "../../../webroot/images/noimage.svg";
import GallaryCarousel from "./GallaryCarousal";
import { getSavedDocuments, getUCRFLeadDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import DocumentHistory from "./DocumentHistory";
import ReSubmissionDocument from "./ReSubmissionDocument";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../services/refinance/executeGraphQLQuery";
import { LEAD_DOCS_REMARKS_QUERY } from "../../../services/refinance/leadDocsRemarks.gql";
// import LeadHelper from "../LeadHelper";
import { IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { SOFT_APPROVAL_CHILD_ID, SOFT_APPROVAL_PARENT_ID, LOAN_APPROVAL_CHILD_ID, LOAN_APPROVAL_PARENT_ID, LOAN_APPROVED, CONTRACT_SIGNED_DOC_CHILD_ID, LOAN_DISBURSED_DOC_CHILD_ID, CONTRACT_SIGNED, LOAN_DISBURSED, DEALER_INCENTIVE_PAID_DOC_CHILD_ID, DEALER_INCENTIVE_RECEIVED_DOC_CHILD_ID, INCENTIVE_PAID, INCENTIVE_RECEIVED, LOST_STATUS } from "../../../config/refinance/constant";

function UploadedTagDocs(props) {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [UploadedFiles, setUploadedFiles] = useState(props.UploadedFiles);
    const [filterFiles, setFilterFileList] = useState([]);
    const [filter_doc_list, setFilterDocList] = useState(props.filter_doc_list);
    const [filter_doc_child_list, setFilterDocChildList] = useState(props.filter_doc_child_list)
    const [isLoading, setIsLoading] = useState(true);
    const [currentFileId, setFileId] = useState(0);
    const [currentFileDocId, setFileDocID] = useState(0);
    const [currentFileParentId, setFileParentID] = useState(0);
    const [filterData, setFilterData] = useState(props.filterData);
    const [enableRemoveTag, setRemoveTag] = useState(false);
    const [documentHistoryList, setDocumentHistoryList] = useState([]);
    const [showDocumentHistoryModal, setShowDocumentHistoryModal] = useState(false);
    const [showResubmisisonModal, setShowResubmissionModal] = useState(false);
    const [docActionType, setDocActionType] = useState('');
    const [leadDetail] = useState(props.leadDetail);
    const [masterdataucrf] = useState(props.masterdataucrf);
    const adp_type = leadDetail?.lead_computation_details?.adp_type === "Yes";
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-basic-details")?.is_edit_access
    // let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    // if (!accessCondition) {
    //     accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    // }
    let { docList } = props;

    useEffect(() => {
        setFilterDocList(props.filter_doc_list);
        setFilterDocChildList(props.filter_doc_child_list)
        setUploadedFiles(props.UploadedFiles)
    }, [props.filter_doc_list, props.filter_doc_child_list, props.UploadedFiles])

    useEffect(() => {
        if (props.filterData.file_id || props.filterData.doc_id) {
            let singleimage = props.filterData.file_id ?
                props.filterFiles.filter(v => v.id == props.filterData.file_id) : props.filterFiles.filter(v => v.doc_id == props.filterData.doc_id);
            if (singleimage.length) {
                setFileId(singleimage?.[0]?.id);
                if (singleimage?.[0]?.doc_id) {
                    setRemoveTag(true);
                    setFileDocID(singleimage?.[0]?.doc_id);
                }
                else {
                    setRemoveTag(false);
                    setFileDocID(0);
                }
                if (singleimage[0].parent_doc_id)
                    setFileParentID(singleimage?.[0].parent_doc_id)
                else setFileParentID(0)

                let newimageArray = [...singleimage]
                props.filterFiles.forEach(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                })
                setFilterFileList(newimageArray);
            } else {
                setFilterFileList(props.filterFiles);
            }
        }
        else
            setFilterFileList(props.filterFiles);
    }, [props.filterFiles, props.filterData.doc_id, props.filterData.file_id]);

    useEffect(() => {
        setIsLoading(false);
        if (filterFiles && filterFiles.length) {

            setFileId(filterFiles[0].id);
            if (filterFiles[0]?.doc_id) {
                setRemoveTag(true);
                setFileDocID(filterFiles[0]?.doc_id);
            } else {
                setFileDocID(0);
            }
            if (filterFiles[0].parent_doc_id) {
                setFileParentID(filterFiles[0].parent_doc_id)
            } else {
                setFileParentID(0)
            }
        } else {

            setFileId(0);
            setRemoveTag(false);
            setFileDocID(0);
            setFileParentID(0)
        }
    }, [filterFiles])

    useEffect(() => {
        let filterFilesOrg = props.filterFiles || [];
        setFilterData(props.filterData);
        if (props.filterData.doc_id) {
            setIsLoading(false);
            let filterFilesnew = filterFilesOrg.filter(v => v.doc_id == props.filterData.doc_id);
            setFilterFileList(filterFilesnew);
        } else {
            if (props.filterData.file_id) {
                let singleimage = filterFilesOrg.filter(v => v.id == props.filterData.file_id);
                setFileId(props.filterData.file_id);
                setRemoveTag(false);
                let newimageArray = [...singleimage]
                filterFilesOrg.forEach(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                })
                setFilterFileList(newimageArray);
            }
        }
    }, [props.filterData, props.filterFiles]);

    const getFileId = (file_id, doc_id, parent_id) => {
        if (file_id !== currentFileId) {
            setFileId(file_id);
            setFileDocID(doc_id);
            setFileParentID(parent_id);
        }
        if (doc_id) {
            setRemoveTag(true);
        } else {
            setRemoveTag(false);
        }

    }

    const tagDoc = async (doc) => {
        if (!user_page_access) {
            window.confirm('User does not have access for this action');
            return false;
        }
        if ((doc.id != SOFT_APPROVAL_CHILD_ID && doc.id != LOAN_APPROVAL_CHILD_ID) && props.activeTab == "first_submit") {
            if (currentFileId) {
                let confirm = window.confirm('Do you want to tag this file ?');
                if (confirm) {
                    await props.tagDoc(doc, currentFileId, filter_doc_list);
                }
            } else {
                toast.error("Invalid file for tagged");
            }
        } else if (props.activeTab != "first_submit") {
            let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
            let is_allowed_data = false
            if(!leadLost){
                if (doc.id == LOAN_APPROVAL_CHILD_ID) {
                    is_allowed_data = leadDetail.customer_timeline_status_ids.findIndex(e => e == LOAN_APPROVED) > -1 ? true : false
                }
                if (doc.id == CONTRACT_SIGNED_DOC_CHILD_ID) {
                    is_allowed_data = leadDetail.customer_timeline_status_ids.findIndex(e => e == CONTRACT_SIGNED) > -1 ? true : false
                }
                if (doc.id == LOAN_DISBURSED_DOC_CHILD_ID) {
                    is_allowed_data = leadDetail.customer_timeline_status_ids.findIndex(e => e == LOAN_DISBURSED) > -1 ? true : false
                }
                if (doc.id == DEALER_INCENTIVE_PAID_DOC_CHILD_ID) {
                    is_allowed_data = leadDetail.customer_timeline_status_ids.findIndex(e => e == INCENTIVE_PAID) > -1 ? true : false
                }
                if (doc.id == DEALER_INCENTIVE_RECEIVED_DOC_CHILD_ID) {
                    is_allowed_data = leadDetail.customer_timeline_status_ids.findIndex(e => e == INCENTIVE_RECEIVED) > -1 ? true : false
                }
                if (is_allowed_data) {
                    if (currentFileId) {
                        let confirm = window.confirm('Do you want to tag this file ?');
                        if (confirm) {
                            await props.tagDoc(doc, currentFileId, filter_doc_list);
                        }
                    } else {
                        toast.error("Invalid file for tagging");
                    }
                } else {
                    toast.error("Invalid Tagging");
                }
            } else {
                toast.error("Invalid Tagging");
            }
        } else {
            toast.error("Invalid Tagging");
        }
    }
    const removeTag = async () => {
        if (currentFileId) {
            let confirm = window.confirm('Are you sure you want to remove tag ?');
            if (confirm) {
                await props.removeTag(current_file, currentFileId, filter_doc_list);
                setFilterData({ doc_id: 0 })
                setRemoveTag(false);
            }
        } else {
            toast.error("Invalid file");
        }
    }


    const markAsApproved = () => {
        let data = {
            lead_id: leadDetail.lead_id,
            user_id: props.user_id || 0,
            doc_status: 2,
            doc_source: "web",
            remark: '',
            update_action_type: 'documents',
            lead_docs: [{
                id: currentFileId,
                doc_id: currentFileDocId,
                parent_doc_id: currentFileParentId
            }]
        };
        let message = "Doc Approved Successfully";
        manageDocAction(data, message);
    }

    /** will handle for both resubmission request as well as approval */
    const manageDocAction = async (leadData, message = null) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            // setIsLoading(true);
            let variables = { approveDocInput: leadData };
            const mutation = gql`
                mutation approve_document($approveDocInput: ApproveDocInput!) {
                    approve_document(approveDocInput: $approveDocInput) {
                    lead_id
                    }
                }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result && result.data) {
                    if (message) toast.success(message);
                    if (result.data) {
                        dispatch(getSavedDocuments(leadDetail.lead_id, client))
                        dispatch(getUCRFLeadDetail(leadDetail.lead_id, client));
                        props.setFilterData({ doc_id: leadData?.lead_docs?.[0]?.doc_id, file_id: leadData?.lead_docs?.[0]?.id })
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }).catch(error => {
                toast.error('Docs already approved/requested. Please refresh the page and check');
                setIsLoading(false);
            });
        } else {
            toast.error("No image found.");
        }
    }

    const getDocumentHistoryList = async (doc_id, lead_id) => {
        if (doc_id) {
            executeGraphQLQuery(LEAD_DOCS_REMARKS_QUERY(lead_id, doc_id), client).then((result) => {
                setDocumentHistoryList(result.data.doc_remark_history);
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        }
    }

    const showDocumentHistory = () => {
        setShowDocumentHistoryModal(true);
        document.body.classList.add("overflow-hidden");
        getDocumentHistoryList(currentFileDocId, leadDetail.lead_id);
    }

    const hideModalDocumentHistory = () => {
        setShowDocumentHistoryModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showResubmission = (action_type) => {
        setShowResubmissionModal(true);
        setDocActionType(action_type);
        document.body.classList.add("overflow-hidden");
    }

    const hideResubmissionModal = () => {
        setShowResubmissionModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    let current_file = currentFileId && filterFiles && filterFiles.length ? filterFiles.find(file => file.id === currentFileId) : null;
    useEffect(() => {
        if (docList?.length) {
            setFilterDocList(docList)
        }
    }, [docList]);

    let doc_list_arr = filter_doc_list?.map((doc, index) => {
        if (doc.id == SOFT_APPROVAL_PARENT_ID) {
            let soft_approval_child = []
            let selected_financier_data = leadDetail?.soft_approval_status?.filter(e => e.soft_approval_status == 1) || []
            let selected_financier_ids = selected_financier_data?.map(e => e.financier_id) || []
            if (selected_financier_ids?.length) {
                for (const ob of selected_financier_ids) {
                    let data_exist = soft_approval_child.findIndex(e => e.financier_id == ob)
                    if (data_exist == -1) {
                        let temp_object = {
                            id: SOFT_APPROVAL_CHILD_ID,
                            parent_id: doc.id,
                            financier_id: ob,
                            name: "Soft Approval Proof",
                            financier_name: ob ? masterdataucrf?.financier_list?.filter(e => e.id == ob)[0]?.financier_name || ob : "",
                            count: doc?.child?.length ? UploadedFiles?.filter(e => e.doc_id == doc.child[0].id && e.financier_id == ob).length : 0,
                            max_upload: 5
                        }
                        soft_approval_child.push(temp_object)
                    }
                }
            } else {
                let temp_object = {
                    id: SOFT_APPROVAL_CHILD_ID,
                    parent_id: doc.id,
                    financier_id: 0,
                    financier_name: "",
                    name: "Soft Approval Proof",
                    count: 0,
                    max_upload: 5
                }
                soft_approval_child.push(temp_object)
            }
            doc.child = soft_approval_child
        } else if (doc.id == LOAN_APPROVAL_PARENT_ID) {
            let soft_approval_child = []
            let selected_financier_data = leadDetail?.financier_loan_approval?.loan_details ? leadDetail?.financier_loan_approval?.loan_details?.filter(e => e?.additional_loan_info?.loan_approved == "yes") : []
            let selected_financier_ids = selected_financier_data?.map(e => e.financier_id) || []
            if (selected_financier_ids?.length) {
                for (const ob of selected_financier_ids) {
                    let data_exist = soft_approval_child.findIndex(e => e.financier_id == ob)
                    if (data_exist == -1) {
                        let temp_object = {
                            id: LOAN_APPROVAL_CHILD_ID,
                            parent_id: doc.id,
                            financier_id: ob,
                            name: "Financier Loan Approval",
                            financier_name: ob ? masterdataucrf?.financier_list?.filter(e => e.id == ob)[0]?.financier_name || ob : "",
                            count: doc?.child?.length ? UploadedFiles?.filter(e => e.doc_id == doc.child[0].id && e.financier_id == ob).length : 0,
                            max_upload: 5
                        }
                        soft_approval_child.push(temp_object)
                    }
                }
            } else {
                let temp_object = {
                    id: LOAN_APPROVAL_CHILD_ID,
                    parent_id: doc.id,
                    financier_id: 0,
                    financier_name: "",
                    name: "Financier Loan Approval",
                    count: 0,
                    max_upload: 5
                }
                soft_approval_child.push(temp_object)
            }
            doc.child = soft_approval_child
        } else {
            doc.child = doc.child.map((ob) => ({
                ...ob,
                count: UploadedFiles.filter(e => e.doc_id == ob.id).length,
                max_upload: 5
            }))
        }
        var childCountArray = doc.child.filter(val => val.count > 0);
        var childRequiredCount = doc.child.filter(val => (val.count >= val.min_upload && val.is_required === '1') || (val.count > 0 && val.count >= val.min_upload));
        var childCountRequired = (doc.child.length) ? doc.child.filter(v => (v.is_required === '1') || (v.count > 0)) : [];
        let docUploadStatus = ((doc.child.length === 0 && doc.count >= doc.min_upload) || (doc.child.length > 0 && childRequiredCount.length === childCountRequired.length && childCountArray.length > 0)
        ) ? 'done' : '';
        if(doc.id == LOAN_APPROVAL_PARENT_ID){
            if(doc.child.filter(val => val.count > 0)?.length){
                docUploadStatus = "done"
            }
        }
        return (
            <div className="taging-list-drop-down" key={doc.id}>
                <ul>
                    <li>
                        {`${doc?.name} ${doc.is_required == '1' ? ' *' : ''}`}
                        {
                            (docUploadStatus === 'done') ? (<span className="stepprogress"><i className="ic-check"></i></span>) : null
                        }
                        <ul>
                            {
                                (doc?.child?.length) ?

                                    doc?.child?.map((doc, index) => {

                                        return (<li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)} >{`${doc.name} ${doc.financier_id ? " | " + doc.financier_name : ""}`} <span className="doc_count">{doc.count} /{doc.max_upload}</span>
                                        </li>)
                                    })
                                    : <li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)}>{`${doc.name}`}
                                        <span className="doc_count">{doc.count} /{doc.max_upload}</span>
                                    </li>
                            }
                        </ul>
                    </li>
                </ul>
            </div>
        )
    })


    let mainCatName = '', subCatName = '', doc_id = (filterData && filterData.doc_id) ? filterData.doc_id : currentFileDocId;
    let financier_id = filterData?.financier_id || 0
    let AppliedDocs = (doc_id) ? (doc_id == SOFT_APPROVAL_CHILD_ID || doc_id == LOAN_APPROVAL_CHILD_ID) ? filter_doc_child_list.filter(v => v.id === doc_id && v.financier_id == financier_id) : filter_doc_child_list.filter(v => v.id === doc_id) : [];
    subCatName = (AppliedDocs && AppliedDocs.length) ? AppliedDocs[0]?.name || "" : "";
    if (doc_id == SOFT_APPROVAL_CHILD_ID || doc_id == LOAN_APPROVAL_CHILD_ID) {
        let selected_financier_id = AppliedDocs?.[0]?.financier_id || 0
        if (selected_financier_id) {
            subCatName = subCatName + " - " + (masterdataucrf?.financier_list?.filter(e => e.id == selected_financier_id)[0]?.financier_name || selected_financier_id)
        }
    }

    if (AppliedDocs && AppliedDocs.length && AppliedDocs[0].parent_id) {
        let AppliedDocsParent = filter_doc_list.filter(v => v.id === AppliedDocs[0].parent_id);
        mainCatName = (AppliedDocsParent && AppliedDocsParent.length) ? AppliedDocsParent[0]?.name || "" : "";
    }
    const disableTagDropdown =
        (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status) && props.activeTab !== "disbursal")
    // (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status) && props.activeTab === "disbursal") 
    // (!adp_type && leadDetail?.status_id === STATUS_CODES["DISBURSEMENT"])

    return (
        <div className="tag-image-bx" key={`${filterFiles.length}${filterData.doc_id}`}>
            <div className={`${(mainCatName || subCatName) ? "image-tagning-drop-down tag-category-sub-category" : "image-tagning-drop-down mark-tag-txt"}`}>
                {
                    (isLoading) ? (<Loader />) : null
                }
                {(
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic"
                            disabled={disableTagDropdown || (props.markAsFreezed && props.activeTab == "first_submit")}
                        >

                            <div className="select-category">
                                {
                                    (mainCatName || subCatName) ? (<span className="tag-main-category">
                                        {(mainCatName || subCatName) ? mainCatName : ''}
                                    </span>) : "Mark as a Tag"
                                }

                                {
                                    (subCatName) ? subCatName : ""
                                }

                            </div>


                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                doc_list_arr?.length ? doc_list_arr : null
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            {user_page_access &&
                <>
                    {
                        (enableRemoveTag && ((current_file && ([0, 2].includes(current_file.doc_status) == 0) && current_file.doc_id != SOFT_APPROVAL_CHILD_ID) && !props.markAsFreezed)) ? (<div className="remove-tag-txt" onClick={removeTag}>
                            Remove Tag
                        </div>) : null
                    }
                </>
            }

            <div className="doument-view-list-gallery">
                {
                    filterFiles && filterFiles.length ?
                        <div className="verfication-img">
                            <GallaryCarousel isLoading={isLoading} slides={filterFiles} document_list={filter_doc_list} getFileId={getFileId} filter_doc_child_list={filter_doc_child_list} />
                        </div>
                        :
                        <div className="noImgTagging">
                            <div className="verfication-img-no-image">
                                <img src={noImage} className="" alt="" />
                                <span className="no-img-txt">No Document Attached</span>
                            </div>
                        </div>
                }

                <div className="image-opt doc-image-opt">
                    {/* Checking for non Pre-disbursal doc */}

                    {user_page_access &&
                        <>
                            {(!["disbursal", "post_disbursal"].includes(props.activeTab)) && (
                                <div className="btn-submission-approved">
                                    {enableRemoveTag && current_file && (
                                        <>
                                            <button className="btn-line" onClick={showDocumentHistory}>View History</button>
                                            {
                                                current_file && +current_file.doc_status === 2 ? <><button className="btn-line btn-approve"><i className="ic-check"></i>Approved2</button></>
                                                    : current_file && +current_file.doc_status === 0 ? <><button className="btn-fill" > <i className="ic-check"></i>Re-submission Requested</button></>
                                                        : (props.activeTab === 'first_submit' && current_file?.parent_doc_id != SOFT_APPROVAL_PARENT_ID) ? <><button disabled={props.markAsFreezed || leadDetail?.basic_details?.doc_status == "pending"} className="btn-line" onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                                            <button className="btn-line" disabled={props.markAsFreezed || leadDetail?.basic_details?.doc_status == "pending"} onClick={markAsApproved}>Mark as Approved</button></>
                                                            : <button className="btn-line" disabled={props.markAsFreezed || leadDetail?.basic_details?.doc_status == "pending"} onClick={markAsApproved}>Mark as Approved</button>
                                            }
                                        </>
                                    )}
                                    {!filterFiles.length && props.activeTab === 'first_submit' && (
                                        <>
                                            <button className="btn-line" onClick={showDocumentHistory}>View History</button>
                                            <button className="btn-line" disabled={props.markAsFreezed || leadDetail?.basic_details?.doc_status == "pending"} onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    }

                </div>
                <div className="view-timeline-popup image-timeline-popup doc-history-timeline scroll-timeline">
                    <DocumentHistory documentHistoryList={documentHistoryList} documentHistoryModelShowFlag={showDocumentHistoryModal} hideModalDocumentHistory={hideModalDocumentHistory} />
                </div>
                <ReSubmissionDocument leadId={leadDetail?.lead_id || 0} documentId={currentFileDocId} documentParentId={currentFileParentId} filterData={filterData} actionType={docActionType} showResubmissionModal={showResubmisisonModal} hideModalReSubmission={hideResubmissionModal} requestResubmission={manageDocAction} user_id={props.user_id} />
            </div>

        </div>
    )
}
export default UploadedTagDocs;