import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Tab, Nav } from 'react-bootstrap';
import UploadedDocumentList from './UploadedDocumentList';
import UploadedTagDocs from './UploadedTagDocs';
import UploadedDocContentList from './UploadedDocContentList';
import Loader from '../../elements/Loader';
import { getSavedDocuments, getUCRFLeadDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation } from "../../../services/refinance/executeGraphQLQuery";
import { LOAN_APPROVAL_PARENT_ID } from "../../../config/refinance/constant";
// import LeadHelper from "../LeadHelper";

const UploadDocumentview = (props) => {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [activeDocTab, setActiveDocTab] = useState(props.activeDocTab);
    const [activeTab] = useState(props.activeTab);
    const [filterData, setFilterData] = useState({ doc_id: 0, file_id: 0, parent_id: 0, financier_id: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [UploadedFiles, setUploadedFiles] = useState([]);

    const { leadDetail, leadDocs, masterdataucrf } = useSelector(({ lead, user, masterdataucrf }) => ({
        userInfo: user.user_information || {},
        leadDetail: lead && lead.ucrfLeadDetail,
        leadDocs: lead && lead.ucrf_lead_docs,
        masterdataucrf: masterdataucrf?.master_data_ucrf || {}
    }));

    useEffect(() => {
        if (leadDocs?.length) {
            setUploadedFiles(leadDocs)
        }
    }, [leadDocs]);

    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);

    const handleSelectTab = (eventKey) => {
        setActiveDocTab(eventKey);
        setFilterData({ doc_id: 0, file_id: 0, parent_id: 0, financier_id: 0 });
    }
    const setFilter = (params) => {
        setFilterData(params);
        setActiveDocTab('tagimage');
    }
    const tagDoc = async (obj, file_id) => {
        if (obj.count < obj.max_upload) {
            await handleChangeOption(file_id, obj.id, obj.parent_id, obj?.financier_id || 0);
        } else {
            toast.error('Tagged Already Max Image');
        }

    }

    const removeTag = async (obj, file_id) => {
        await handleChangeOption(file_id, 0, 0, obj?.financier_id || 0);
    }

    const handleChangeOption = async (id, doc_id, parent_id, financier_id = 0) => {
        setIsLoading(true);
        let variables = {
            updateDocInput: {
                id: id,
                lead_id: leadDetail.lead_id,
                parent_doc_id: parent_id,
                doc_id: doc_id,
                doc_source: 'web',
                tab_name: activeTab || "",
                status: 1,
                user_id: authUser && (authUser.id || 0),
                financier_id: financier_id || 0,
            }
        };
        const mutation = gql`
            mutation Update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                id,
                lead_id
                doc_id
                }
            }`;

        await executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                toast.success("Success");
                setFilterData((e) => ({ ...e, doc_id: 0, file_id: id, financier_id: financier_id }))
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            console.log("error", error)
            toast.error(error.toString().replace('ApolloError:', ''));
            setIsLoading(false);
        });
        dispatch(getSavedDocuments(leadDetail.lead_id, client))
        dispatch(getUCRFLeadDetail(leadDetail.lead_id, client));
    }

    const send4TagFile = (file_id, financier_id) => {
        setFilterData((filterData) => ({ ...filterData, doc_id: 0, file_id: file_id, financier_id }))
        setActiveDocTab('tagimage');
    }

    let document_list = props?.docCategoryList || [];
    let filter_doc_list = document_list.filter(data => data.doc_category === activeTab);
    let filter_doc_child_list = [];
    filter_doc_list && filter_doc_list.forEach(data => {
        filter_doc_child_list = [...filter_doc_child_list, ...data.child]
    })
    let filterFiles = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.parent_doc_id === o2.parent_id || o1.parent_doc_id === 0)));
    let newFilterFiles = filterFiles.filter(e=> e.parent_doc_id != LOAN_APPROVAL_PARENT_ID)
    let selected_financier_data = leadDetail?.financier_loan_approval?.loan_details ? leadDetail?.financier_loan_approval?.loan_details?.filter(e => e?.additional_loan_info?.loan_approved == "yes") : []
    let selected_financier_ids = selected_financier_data?.map(e => e.financier_id) || []
    let loan_approved_files = filterFiles.filter(e=> e.parent_doc_id == LOAN_APPROVAL_PARENT_ID)
    loan_approved_files = loan_approved_files.filter(e=>selected_financier_ids.includes(e.financier_id))
    filterFiles = [...newFilterFiles, ...loan_approved_files]
    // let { temp, docList } = useMemo(()=> {
    //     // return LeadHelper.getFilterDocumentList(UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail)
    // }, [UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail])
    return (
        <div className="uplaod-view-tabs" key={`${activeTab}${activeDocTab}${filterFiles.length}`}>
            {
                (isLoading) ? <Loader /> : null
            }
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeDocTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={activeDocTab === 'upload'} eventKey="upload">Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={activeDocTab === 'tagimage'} eventKey="tagimage">Tag Image</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane active={activeDocTab === 'upload'} eventKey="upload">
                        <UploadedDocumentList filter_doc_list={filter_doc_list} filterFiles={filterFiles} filter_doc_child_list={filter_doc_child_list} document_list={props?.docCategoryList || []} key={UploadedFiles.length} getDocumentlist={UploadedFiles} send4TagFile={send4TagFile} activeTab={activeTab} markAsFreezed={props.markAsFreezed} />
                    </Tab.Pane>
                    <Tab.Pane active={activeDocTab === 'tagimage'} eventKey="tagimage">
                        <UploadedTagDocs filter_doc_list={filter_doc_list} filter_doc_child_list={filter_doc_child_list} UploadedFiles={UploadedFiles} document_list={document_list} activeTab={activeTab} key={UploadedFiles.length} filterFiles={filterFiles} filterData={filterData} tagDoc={tagDoc} setFilterData={setFilterData} removeTag={removeTag} user_id={authUser?.id || 0} leadDetail={leadDetail} markAsFreezed={props.markAsFreezed} masterdataucrf={masterdataucrf} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <div className="document-list-panel">
                <UploadedDocContentList filter_doc_list={filter_doc_list} UploadedFiles={UploadedFiles} document_list={props?.docCategoryList || []} activeTab={activeTab} key={UploadedFiles.length} setFilter={setFilter} leadDetails={leadDetail} markAsFreezed={props.markAsFreezed} masterdataucrf={masterdataucrf} />
            </div>
        </div>
    )
}
export default UploadDocumentview;