import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useSelector } from "react-redux";
import FormsyInputField from "../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { executeGraphQLQuery as executeGraphQLQueryUCF } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { Nav, Tab } from "react-bootstrap";
import { GET_ROLES_WITH_USERS } from "../../../services/config.gql";
import crypto from "../../../config/crypto";
import Loader from "../../elements/Loader";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../services/refinance/executeGraphQLQuery";
import { UCRF_GET_ALL_DEALER_LIST } from "../../../services/refinance/refinance-config.gql";
import { UCRF_SAVE_LEAD_DETAILS } from "../../../services/refinance/leads.gql";
import FormsySelect from "../../elements/FormsySelect";
import UploadMoreICn from "../../../webroot/images/upload_icn.svg";
import GeneralService from "../../../services/generalService";
import { PH_REFINANCE_BUCKET_NAME } from "../../../config/refinance_constant";
import { BUSINESS_LINE } from "../../../config/refinance/constant";

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);

const RefinanceCreateLead = () => {
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;
    const { master_data_ucrf } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    }));

    const [basicDetails, setBasicDetails] = useState({ created_by: user_id });
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [activeTab, setActiveTab] = useState("sales_officer");
    const [dealershipName, setDealershipName] = useState([]);
    const [userList, setUserList] = useState([]);
    const [documentName, setDocumentName] = useState("");
    const [customerDoc, setCustomerDoc] = useState("");
    const [loading, setLoading] = useState(false);

    const client = useApolloClient();
    let navigate = useNavigate();

    useEffect(() => {
        let role_id = "8";
        if (activeTab === "sales_officer") {
            role_id = "6"
        } else if (activeTab === "sales_manager") {
            role_id = "5"
        }
        getUserList(role_id);
    }, [activeTab]);

    const getDealerList = (userId = 0) => {
        setLoading(true);
        executeGraphQLQuery(UCRF_GET_ALL_DEALER_LIST(1, userId, ""), client)
            .then((result) => {
                let dealer_list = result?.data?.getAllDealerList?.filter((data) => ![null, ""].includes(data?.label));
                setLoading(false);
                setDealershipName(dealer_list);
            })
            .catch((errors) => {
                setLoading(false);
                toast.error("Somethig went wrong ,please try again");
            });
    };

    const getUserList = async (role_id) => {
        setLoading(true);
        let user_list = [],
            userlist = [];
        await executeGraphQLQueryUCF(GET_ROLES_WITH_USERS, client)
            .then((response) => {
                if (response && response.data && response.data.get_roles_with_users) {
                    user_list = response.data.get_roles_with_users.find((obj) => obj.role_id === role_id)?.users || [];
                    user_list &&
                        user_list.map((v) => {
                            userlist.push({ value: v.id, label: v.name });
                            return v;
                        });
                    setLoading(false);
                    setUserList(userlist);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const onTabChange = async (tab, event) => {
        if (event.type === "click") {
            setActiveTab(tab);
            setBasicDetails(() => ({
                name: "",
                phone: "",
                dob: "",
                plate_number: "",
                agent_name: "",
                agent_mobile: "",
                dealer_id: "",
            }));
        }
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value !== undefined && field_name) {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [field_name]: dateFormat(date, "yyyy-mm-dd"),
            }));
        }
    };

    const handleChange = (field_name, data) => {
        if (field_name === "govt_id_type") {
            setDocumentName(data?.label || "");
        }
        if (field_name === "dealer_id") {
            let mapped_dealer_id = dealershipName?.find((dealer_list) => dealer_list?.value === data?.value)?.mapped_dealer_id;
            setBasicDetails((currentValue) => ({
                ...currentValue,
                inventory_id: 0,
                dealer_car_id: null,
                make_year: null,
                make_id: 0,
                model_id: 0,
                variant_id: 0,
                make_name: "",
                model_name: "",
                variant_name: "",
                color: "",
                is_dealer_unmapped: mapped_dealer_id !== "" ? "0" : "1",
                [field_name]: data?.value,
            }));
        } else {
            setBasicDetails((currentValue) => ({
                ...currentValue,
                [field_name]: data?.value,
            }));
        }
        if (["so_id", "agent_id", "sm_id"].includes(field_name)) getDealerList(data?.value);
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            toast.error("Please select a file to upload.");
            return;
        }
        setCustomerDoc(selectedFile);
    };

    const triggerFileInput = () => {
        document.getElementById("fileupload").click();
    };

    useEffect(() => {
        return () => {
            if (customerDoc) {
                URL.revokeObjectURL(customerDoc);
            }
        };
    }, [customerDoc]);

    const uploadDocument = async (fileObject) => {
        const formData = new FormData();
        formData.append("upload_type", PH_REFINANCE_BUCKET_NAME);
        formData.append("lead_id", 0);
        formData.append("images", fileObject);
        try {
            const response = await GeneralService.uploadFilesToS3(formData, { "Content-Type": "multipart/form-data" });
            const doc_path = response?.data?.data?.[0]?.file_url || "";
            if (doc_path) {
                return doc_path;
            } else {
                return "";
            }
        } catch (error) {
            toast.error("An error occurred while uploading the file.");
        }
    };

    const handleSubmit = async () => {
        if (!allowSubmit || !customerDoc) return;
        setLoading(true);
        let createLeadBasicInput = {
            source_type: basicDetails.source_type,
            customer_name: basicDetails.name,
            customer_mobile_no: basicDetails.phone,
            date_of_birth: basicDetails.dob,
            vehicle_plate_number: basicDetails.plate_number,
            customer_govt_id: basicDetails.govt_id_type,
            loan_purpose: basicDetails.loan_purpose || "",
            updated_by: user_id,
            created_by: user_id,
            govt_id_proof: null,
        };

        let docPath = await uploadDocument(customerDoc);
        if (docPath) {
            createLeadBasicInput.govt_id_proof = docPath;
        }

        if (createLeadBasicInput.source_type === "agent") {
            createLeadBasicInput.agent_name = basicDetails.agent_name;
            createLeadBasicInput.agent_mobile_no = basicDetails.agent_mobile;
        } else if (createLeadBasicInput.source_type === "dealer") {
            createLeadBasicInput.dealer_id = basicDetails.dealer_id;
        } else if (createLeadBasicInput.source_type === "individual") {
            createLeadBasicInput.source_description = basicDetails.source_description;
        }

        let lead_user_id, lead_role_id;

        if (activeTab === "sales_officer") {
            lead_user_id = parseFloat(basicDetails?.so_id);
            lead_role_id = 6;
        } else if (activeTab === "sales_manager") {
            lead_user_id = parseFloat(basicDetails?.sm_id);
            lead_role_id = 5;
        } else if (activeTab === "agent") {
            lead_user_id = parseFloat(basicDetails?.agent_id);
            lead_role_id = 8;
        }

        let variables = {
            tab_id: parseFloat(1),
            user_id: parseFloat(lead_user_id),
            role_id: parseFloat(lead_role_id),
            basic_details: createLeadBasicInput,
        };

        executeGraphQLMutation(UCRF_SAVE_LEAD_DETAILS, variables, client)
            .then((resp) => {
                let response = resp?.data?.saveLeadDetails || null;
                if (!response) {
                    toast.error(response?.error || "Something went wrong");
                } else {
                    if (response.lead_id) {
                        setBasicDetails((currentValue) => ({
                            ...currentValue,
                            id: response?.lead_id,
                        }));
                        toast.success("UCRF Application Created Successfully! Lead id is " + BUSINESS_LINE + response?.lead_id);
                    } else {
                        toast.error(response.message);
                    }
                }
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="bg-white p-lg-t" key={"create_lead"}>
            {loading ? <Loader /> : null}
            <div className="container">
                <div className="lead-form-heading applicatiom-method-heading">
                    <h3>Add Used Car Refinance Application</h3>
                </div>
                <div className="lead-detail-tabs add-user-applicatiom-method">
                    <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(tab, e) => onTabChange(tab, e)} defaultActiveKey="finance-0">
                        <Nav variant="pills" className="flex-column nav nav-pills">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link eventKey={"sales_officer"}>Sales Officer</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"agent"}>Agent</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"sales_manager"}>Sales Manager</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Tab.Container>
                </div>
                <div className="form-tab-right-panel">
                    <Formsy
                        className=""
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleSubmit}
                    >
                        <div className="row">
                            <fieldset class="single-select col-md-3">
                                <FormsySelect
                                    value={
                                        basicDetails?.["source_type"]
                                            ? master_data_ucrf.source_type.filter(({ value }) => value === basicDetails["source_type"])
                                            : ""
                                    }
                                    onChange={(e) => handleChange("source_type", e)}
                                    options={userList || []}
                                    name="source_type"
                                    id="source_type"
                                    disabled={!!basicDetails?.id}
                                    inputProps={{
                                        isDisabled: !!basicDetails?.id,
                                        options: master_data_ucrf?.source_type ? master_data_ucrf.source_type : [],
                                        placeholder: "Select Source Type",
                                        className: "react-select",
                                        classNamePrefix: "react-select",
                                        value: basicDetails?.["source_type"]
                                            ? master_data_ucrf.source_type.filter(({ value }) => value === basicDetails["source_type"])
                                            : "",
                                    }}
                                    required={true}
                                    showAsterisk={true}
                                    readOnly={!!basicDetails?.id}
                                />
                            </fieldset>

                            {activeTab === "sales_officer" && (
                                <fieldset class="single-select col-md-3">
                                    <FormsySelect
                                        value={basicDetails?.["so_id"] ? userList.filter(({ value }) => value === basicDetails["so_id"]) : ""}
                                        onChange={(e) => handleChange("so_id", e)}
                                        options={userList || []}
                                        name="so_id"
                                        id="so_id"
                                        disabled={!!basicDetails?.id}
                                        inputProps={{
                                            isDisabled: !!basicDetails?.id,
                                            options: userList?.length ? userList : [],
                                            placeholder: "Select Sales Officer",
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: basicDetails?.["so_id"] ? userList.filter(({ value }) => value === basicDetails["so_id"]) : "",
                                        }}
                                        required={true}
                                        showAsterisk={true}
                                        readOnly={!!basicDetails?.id}
                                    />
                                </fieldset>
                            )}
                            {activeTab === "sales_manager" && (
                                <fieldset class="single-select col-md-3">
                                    <FormsySelect
                                        value={basicDetails?.["sm_id"] ? userList.filter(({ value }) => value === basicDetails["sm_id"]) : ""}
                                        onChange={(e) => handleChange("sm_id", e)}
                                        options={userList || []}
                                        name="sm_id"
                                        id="sm_id"
                                        disabled={!!basicDetails?.id}
                                        inputProps={{
                                            isDisabled: !!basicDetails?.id,
                                            options: userList?.length ? userList : [],
                                            placeholder: "Select Sales Manager",
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: basicDetails?.["sm_id"] ? userList.filter(({ value }) => value === basicDetails["sm_id"]) : "",
                                        }}
                                        required={true}
                                        showAsterisk={true}
                                        readOnly={!!basicDetails?.id}
                                    />
                                </fieldset>
                            )}
                            {activeTab === "agent" && (
                                <fieldset class="single-select col-md-3">
                                    <FormsySelect
                                        value={basicDetails?.["agent_id"] ? userList.filter(({ value }) => value === basicDetails["agent_id"]) : ""}
                                        onChange={(e) => handleChange("agent_id", e)}
                                        options={userList || []}
                                        name="agent_id"
                                        id="agent_id"
                                        disabled={!!basicDetails?.id}
                                        inputProps={{
                                            isDisabled: !!basicDetails?.id,
                                            options: userList?.length ? userList : [],
                                            placeholder: "Select Agent",
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: basicDetails?.["agent_id"] ? userList.filter(({ value }) => value === basicDetails["agent_id"]) : "",
                                        }}
                                        required={true}
                                        showAsterisk={true}
                                        readOnly={!!basicDetails?.id}
                                    />
                                </fieldset>
                            )}

                            {basicDetails["source_type"] === "dealer" ? (
                                <fieldset className="single-select col-md-3">
                                    <FormsySelect
                                        value={basicDetails?.["dealer_id"] ? dealershipName.filter(({ value }) => value === basicDetails["dealer_id"]) : ""}
                                        onChange={(e) => handleChange("dealer_id", e)}
                                        options={dealershipName || []}
                                        name="dealer_id"
                                        id="dealer_id"
                                        disabled={!!basicDetails?.id}
                                        inputProps={{
                                            isDisabled: !!basicDetails?.id,
                                            options: dealershipName?.length ? dealershipName : [],
                                            placeholder: "Select Dealer",
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: basicDetails?.["dealer_id"] ? dealershipName.filter(({ value }) => value === basicDetails["dealer_id"]) : "",
                                        }}
                                        required={true}
                                        showAsterisk={true}
                                        readOnly={!!basicDetails?.id}
                                    />
                                </fieldset>
                            ) : null}

                            <fieldset className="form-filed col-md-3">
                                <FormsyInputField
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={basicDetails?.name}
                                    placeholder=" "
                                    label="Customer Name"
                                    onChange={handleInputChange}
                                    validations={{ isWords: true }}
                                    validationError={basicDetails.name ? "Must only contain alphabets" : ""}
                                    readOnly={!!basicDetails?.id}
                                    required={true}
                                    disabled={!!basicDetails?.id}
                                    showAsterisk={true}
                                />
                            </fieldset>

                            <fieldset className="form-filed col-md-3">
                                <FormsyInputField
                                    id="phone"
                                    name="phone"
                                    type="pattern-format"
                                    value={basicDetails?.phone}
                                    placeholder=" "
                                    label="Customer Phone Number"
                                    onChange={(data) => handlePatternFormatChange(data, "phone")}
                                    format="+63 ### ### ######"
                                    validations="isValidPhoneNumber"
                                    validationError=""
                                    readOnly={!!basicDetails?.id}
                                    required={true}
                                    disabled={!!basicDetails?.id}
                                    showAsterisk={true}
                                />
                            </fieldset>

                            <fieldset className="form-filed col-md-3 createlead-datepicker">
                                <FormsyDatePicker
                                    name="dob"
                                    selectedDate={basicDetails?.dob ? new Date(basicDetails.dob) : ""}
                                    placeholder="Customer DOB"
                                    maxDate={DOB_MAX_DATE}
                                    value={basicDetails?.dob ? new Date(basicDetails.dob) : ""}
                                    onDateSelect={(date) => handleDateChange(date, "dob")}
                                    dateFormat="dd MMM, yyyy"
                                    required={true}
                                    showAsterisk={true}
                                    hasError={basicDetails.hasOwnProperty("dob") && !basicDetails.dob}
                                    readOnly={!!basicDetails?.id}
                                    disabled={!!basicDetails?.id}
                                />
                            </fieldset>

                            <fieldset className="form-filed col-md-3">
                                <FormsyInputField
                                    id="plate_number"
                                    name="plate_number"
                                    type="text"
                                    value={basicDetails?.plate_number}
                                    placeholder=" "
                                    label="Vehicle Plate No."
                                    onChange={handleInputChange}
                                    readOnly={!!basicDetails?.id}
                                    required={true}
                                    disabled={!!basicDetails?.id}
                                    showAsterisk={true}
                                />
                            </fieldset>

                            <fieldset className="single-select col-md-3">
                                <FormsySelect
                                    value={
                                        basicDetails?.["govt_id_type"]
                                            ? master_data_ucrf.customer_govt_id.filter(({ value }) => value === basicDetails["govt_id_type"])
                                            : ""
                                    }
                                    onChange={(e) => handleChange("govt_id_type", e)}
                                    options={master_data_ucrf.customer_govt_id || []}
                                    name="govt_id_type"
                                    id="govt_id_type"
                                    disabled={!!basicDetails?.id}
                                    inputProps={{
                                        isDisabled: !!basicDetails?.id,
                                        options: master_data_ucrf.customer_govt_id?.length ? master_data_ucrf.customer_govt_id : [],
                                        placeholder: "Customer's Govt. ID",
                                        className: "react-select",
                                        classNamePrefix: "react-select",
                                        value: basicDetails?.["govt_id_type"]
                                            ? master_data_ucrf.customer_govt_id.filter(({ value }) => value === basicDetails["govt_id_type"])
                                            : "",
                                    }}
                                    required={true}
                                    showAsterisk={true}
                                    readOnly={!!basicDetails?.id}
                                />
                            </fieldset>

                            <fieldset className="form-filed col-md-3">
                                <FormsyInputField
                                    id="loan_purpose"
                                    name="loan_purpose"
                                    type="text"
                                    value={basicDetails?.loan_purpose}
                                    placeholder=" "
                                    label="Loan Purpose"
                                    onChange={handleInputChange}
                                    validations="isWords"
                                    validationError={basicDetails.loan_purpose ? "Must only contain alphabets" : ""}
                                    readOnly={!!basicDetails?.id}
                                    disabled={!!basicDetails?.id}
                                    showAsterisk={false}
                                />
                            </fieldset>

                            {basicDetails["source_type"] === "agent" ? (
                                <>
                                    <fieldset className="form-filed col-md-3">
                                        <FormsyInputField
                                            id="agent_name"
                                            name="agent_name"
                                            type="text"
                                            value={basicDetails?.agent_name}
                                            placeholder=" "
                                            label="Agent Name"
                                            onChange={handleInputChange}
                                            validations="isWords"
                                            validationError={basicDetails.agent_name ? "Must only contain alphabets" : ""}
                                            readOnly={!!basicDetails?.id}
                                            required={true}
                                            disabled={!!basicDetails?.id}
                                            showAsterisk={true}
                                        />
                                    </fieldset>

                                    <fieldset className="form-filed col-md-3">
                                        <FormsyInputField
                                            id="agent_mobile"
                                            name="agent_mobile"
                                            type="pattern-format"
                                            value={basicDetails?.agent_mobile}
                                            placeholder=" "
                                            label="Agent Mobile Number"
                                            onChange={(data) => handlePatternFormatChange(data, "agent_mobile")}
                                            format="+63 ### ### ######"
                                            validations="isValidPhoneNumber"
                                            validationError=""
                                            readOnly={!!basicDetails?.id}
                                            required={true}
                                            disabled={!!basicDetails?.id}
                                            showAsterisk={true}
                                        />
                                    </fieldset>
                                </>
                            ) : null}

                            {basicDetails["source_type"] === "individual" ? (
                                <fieldset className="form-filed col-md-3">
                                    <FormsyInputField
                                        id="source_description"
                                        name="source_description"
                                        type="text"
                                        value={basicDetails?.source_description}
                                        placeholder=" "
                                        label="Source Description"
                                        onChange={handleInputChange}
                                        validations="isExisty"
                                        validationError={basicDetails.source_description ? "Source description is required" : ""}
                                        readOnly={!!basicDetails?.id}
                                        required={true}
                                        disabled={!!basicDetails?.id}
                                        showAsterisk={true}
                                    />
                                </fieldset>
                            ) : null}
                        </div>
                        {documentName ? (
                            <div className="row">
                                <fieldset className="form-filed col-md-8">
                                    <div className="lead-form-heading applicatiom-method-heading">
                                        <h2>{documentName+"*"}</h2>
                                    </div>
                                    <div className="uplaod-view-tabs">
                                        <div className="upload-document-view-bx">
                                            <div className="col-md-4">
                                                <div className="img-bx-select-opt">
                                                    <div className="image-bx more-file-upload" onClick={triggerFileInput}>
                                                        {customerDoc ? (
                                                            <>
                                                                {customerDoc && customerDoc?.type?.split('/').pop() == "pdf" || customerDoc?.type?.split('/').pop() == "PDF" ?
                                                                    <a href={customerDoc} target="_blank" rel="noopener noreferrer"><i className="ic-pdf-file icons-normal"></i></a> :
                                                                    <img alt="Uploaded document" src={URL.createObjectURL(customerDoc)} className="upload-icn" />
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img alt="Upload more icon" src={UploadMoreICn} className="upload-icn" />
                                                                <p>Add Document</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        disabled={!!basicDetails?.id}
                                        aria-label="upload form"
                                        type="file"
                                        name="fileName"
                                        id="fileupload"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                </fieldset>
                            </div>
                        ) : null}

                        {!basicDetails?.id ? (
                            <div className="btn-save-remarks t-center btn-bottom">
                                <button aria-label="save naxt" type="submit" className="btn-primary" disabled={!allowSubmit || !customerDoc}>
                                    Submit
                                </button>
                            </div>
                        ) : (
                            <div className="btn-save-remarks t-center btn-bottom">
                                <button
                                    aria-label="save naxt"
                                    type="button"
                                    className="btn-line m-sm-r"
                                    onClick={() => {
                                        navigate(`/dashboard`);
                                    }}
                                >
                                    Go to Home
                                </button>
                                <button
                                    aria-label="save naxt"
                                    type="button"
                                    className="btn-primary"
                                    onClick={() => {
                                        navigate(`/refinance/lead-detail/basic-details/${crypto.encode(basicDetails?.id)}`);
                                    }}
                                >
                                    View Loan ID
                                </button>
                            </div>
                        )}
                    </Formsy>
                </div>
            </div>
        </div>
    );
};
export default RefinanceCreateLead;
