import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from './DocumentGallery';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import DealerFinancierPaymentExchangeJson from './formFields/dealerFinancierPaymentExchange.json';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import { useOutletContext } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../store/action/allAction';
import { LEADS_REMARK_QUERY_FOR_RECEIVED_AMT } from '../../services/leads.gql';
import dateFormat from "dateformat";


const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
const DealerFinancierPaymentExchange = (props) => {
    const client = useApolloClient();
    let tab=props.tab;
    //is_completed=false;
    const dispatch = useDispatch();
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [dealerFinancierExchangeData, setDealerFinancierExchangeData] = useState({});
    const [dateSelected, setDateSelected] = useState(true);
    let [ maxTime, setMaxTime ] = useState(now.setHours(currhour, currmin, 0));
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    const formFields = DealerFinancierPaymentExchangeJson[tab];
    let current_page_fields = formFields.form_fields || [];
    // console.log(current_page_fields,"ffffffffffffffffffffff")
    const { leadDetail,remarksHistory } = useSelector(({ lead }) => {
        return {
            leadDetail: lead.leadDetail,
            remarksHistory: lead.remarksHistory || [],
        }
    });
    let [remarks, setRemarks] = useState([]);
    let [remarksToShow, setRemarksToShow] = useState([]);
    // console.log(leadDetail,"llllllllllllllllllllllllll")
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];
    let form_read_only = (leadDetail?.lead_status_dates?.main_dealer_paid && ['main_dealer_payment'].includes(tab)) || false;
    let onhold_read_only = (leadDetail?.lead_status_dates?.onhold_dealer_paid && ['onhold_dealer_payment'].includes(tab)) || false;
    let split1_paid = (leadDetail?.lead_status_dates?.split1_paid && ['split_dealer1_payment'].includes(tab)) || false;
    let split2_paid = (leadDetail?.lead_status_dates?.split2_paid && ['split_dealer2_payment'].includes(tab)) || false;
    
    let mainDealerFlag = leadDetail?.lead_computation_details?.adp_type === "Yes" && true || leadDetail?.lead_computation_details?.loan_amount_pass_through === 'yes' ? false : true;
    let onHoldDealerFlag = true;
    console.log("leadDetail?.lead_computation_details?.di_amount_pass_through>>>>>>>>>", leadDetail?.lead_computation_details?.di_amount_pass_through);
    let splitPaymentFlag = leadDetail?.lead_computation_details?.di_amount_pass_through === 'yes' ? false : true;
    let showSplit2Date = !mainDealerFlag ? (!!leadDetail?.split_payment?.filter(data => data.payment_type === 'split_dealer2' || data.payment_type === 'split_dealer')?.length || leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax === 0 ): leadDetail?.lead_computation_details?.loan_amount_pass_through === 'yes' ? false : true;
    useEffect(() => {
        const fetchData = async () => {
            await getDealerFinancierExchangeData(leadDetail);
            if (tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved') await getLeadRemarks(leadDetail)
        };
        fetchData();
        setDateSelected(true)
    }, [leadDetail,tab]);


    // if(tab==='loan_value_recieved'){
    //     is_completed=leadDetail?.tab_status?.payment_received_from_financier?.laon_value_recieved?.is_completed;
    // }else if(tab==='paid_to_dealer'){
    //     is_completed=leadDetail?.tab_status?.payment_to_dealer?.is_completed;
    // }else if(tab==='dealer_incentive_recieved'){
    //     is_completed=leadDetail?.tab_status?.payment_received_from_financier?.dealer_incentive_received?.is_completed;
    // }


    const getDealerFinancierExchangeData = (data) => {
        let lead_detail_data = {};
        current_page_fields.forEach(obj => {
            if(obj.type==="date-picker" && data.lead_status_dates && data.lead_status_dates.hasOwnProperty(obj.name))
            {
                lead_detail_data = {
                    ...lead_detail_data,
                    [obj.name]: data.lead_status_dates && data.lead_status_dates[obj.name] ? new Date(data.lead_status_dates && data.lead_status_dates[obj.name]) : null
                }
            }
            else if (data.lead_details && data.lead_details.hasOwnProperty(obj.name)) {
                lead_detail_data = {
                    ...lead_detail_data,
                    [obj.name]: data.lead_details && data.lead_details[obj.name]
                }
            }
        })
        setDealerFinancierExchangeData(lead_detail_data);
    }
    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
      };
    
      const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
      };
    let sectionInfo = {section : "" , sub_section: ""}

    if(tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved'){
        sectionInfo = {section : "Payment Recieved From Financier" , sub_section: props.name}
    }else{
        sectionInfo = {section : props.name , sub_section: ""}
    }

    const getLeadRemarks = async (leadDetail) => {
        try {
            let variables = { lead_id: leadDetail?.id, remark_type: [3, 4] }
            executeGraphQLQuery(LEADS_REMARK_QUERY_FOR_RECEIVED_AMT, client, variables).then((response) => {
                if (response && response.data && response.data.get_remarks) {
                    let data = response.data.get_remarks;
                    if (data?.length) {
                        setRemarks(data);
                        setRemarksToShow([data?.[0]]);
                    }
                }
            })
        } catch (err) {
            console.log(err)
        }
    }
    const showMoreLessRemarks = async (action) => {
        if(action === "more") setRemarksToShow([...remarks]);
        if(action === "less") remarks?.length && setRemarksToShow([remarks?.[0]]);
    }

    const handleDateChange = (target_name, field_type, event) => {
        if(field_type === "date-picker" ){
            if(compareDate(event)) setMaxTime(now.setHours(23, 59, 59))
            else setMaxTime(now.setHours(currhour, currmin, 0))
            event ? setDateSelected(false) : setDateSelected(true);
            setDealerFinancierExchangeData(currentValue => ({
                ...currentValue,
                [target_name]: event
            }))
        } 
    }

    function compareDate(selectedDate) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(selectedDate); 
        d.setHours(0, 0, 0, 0);
        if(d < today){ 
            return true;
        }
        return false;
    }

    const handleSubmit = async() => {
        let mutation = gql`
        mutation paidtodelaer($paidtodealer: [PaidtoDealer!]!) {
            savepaidtodealer(paidtodealer: $paidtodealer) {
                successids
                failedids
            }
        }
        `;
        let variables ={ paidtodealer:[{
            lead_id:leadDetail?.id,
            main_dealer_paid: !mainDealerFlag ? dealerFinancierExchangeData?.main_dealer_paid : null,
            split1_paid: !splitPaymentFlag ? dealerFinancierExchangeData?.split1_paid : null,
            split2_paid: !splitPaymentFlag ? dealerFinancierExchangeData?.split2_paid : null,
            user_id:UserInfo?.id,
            updated_by_source: 'web'
        }]}
        try {
            await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(leadDetail?.id,client))
            toast.success("Success");
        } catch (error) {
            toast.error("Error");
        }
    }

    return (<React.Fragment >
        <div className="image-form-outer" key={`${tab}`}>
            {/* {loading ? <Loader /> : null} */}
            <div className="image-tab-panel">
            <DocumentGallery documentCategoryList={documentCategoryList}  />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className={" switch-toggle"}>
                        {tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved' ? <div className="lead-form-heading">
                            <h2>Payment Recieved From Financier</h2>
                        </div> : null}
                        <div className="lead-form-heading">
                            <h2>{props.name}</h2>
                        </div>
                        <div className="soft-approvel-sec">
                            <Formsy
                                className="lead-form-filed radio-btn"
                                autoComplete="off"
                                onValid={() => ""}
                                onInvalid={() => ""}
                                onValidSubmit={""}
                            >
                                <div className="row">
                                {current_page_fields.map((field) => (
                                    ["number-format", "pattern-format"].includes(field.type) ? (
                                        <fieldset class="form-filed col-md-12 ">
                                            <div className="material">
                                                <FormsyInputField
                                                    id={field.id}
                                                    name={field.name}
                                                    type={field.type}
                                                    value={(dealerFinancierExchangeData && (dealerFinancierExchangeData[field.name] || ""))}
                                                    placeholder=" "
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator={','}
                                                    decimalSeparator={'.'}
                                                    label={field.label}
                                                    onChange={e => console.log(e)}
                                                    format={field.ApplyNumberformat ? field.format : ""}
                                                    required={field.required}
                                                    readOnly={field.readOnly}
                                                    disabled={field.disabled}
                                                />
                                            </div>
                                        </fieldset>
                                    ) :
                                        field.type === "date-picker" ? (
                                            <fieldset class="form-filed col-md-12 ">
                                                <div className="material">
                                                    <FormsyDatePicker
                                                        col='col-md-12'
                                                        name={field.name}
                                                        selectedDate={dealerFinancierExchangeData && dealerFinancierExchangeData[field.name] && new Date(dealerFinancierExchangeData[field.name])}
                                                        value={(dealerFinancierExchangeData && (dealerFinancierExchangeData[field.name] && (Date.parse(dealerFinancierExchangeData[field.name]) || "")))}
                                                        placeholder={field.label}
                                                        maxDate={new Date()}
                                                        minTime={now.setHours(0, 0, 0)}
                                                        maxTime={maxTime}
                                                        onDateSelect={(date) => handleDateChange(field.name, field.type, date)}
                                                        dateFormat="dd MMM, yyyy h:mm aa"
                                                        showTimeSelect={true}
                                                        validations={field.validations}
                                                        validationError={
                                                            "Please Fill the Details"
                                                        }
                                                        required={field.required}
                                                        readOnly={(mainDealerFlag && field.name === 'main_dealer_paid') || (onHoldDealerFlag && field.name === 'onhold_dealer_paid' ) || (splitPaymentFlag && field.name === 'split1_paid') || (field.name === 'split2_paid' && showSplit2Date) || form_read_only || onhold_read_only || split1_paid || split2_paid}
                                                        disabled={(mainDealerFlag && field.name === 'main_dealer_paid') || (onHoldDealerFlag && field.name === 'onhold_dealer_paid' ) || (splitPaymentFlag && field.name === 'split1_paid') || (field.name === 'split2_paid' && showSplit2Date) || form_read_only || onhold_read_only || split1_paid || split2_paid}
                                                        hasError={''}
                                                        clearDate={false}
                                                    />
                                                </div>
                                            </fieldset>
                                        ) : ''
                                ))}
                                </div>

                                

                                {remarksToShow?.length ? <div className="timeline-history-tabel incentive-received-remarks">
                                    <table>
                                        <tbody>
                                            {remarksToShow?.map((remark) =>
                                            (
                                                <tr>
                                                    <td width="25%">
                                                        <span className="date remark-text">{dateFormat(remark?.created_date, 'd mmm')}</span>&nbsp;
                                                        <span className="time">{dateFormat(remark?.created_date, 'hh:MM TT')}</span></td>
                                                    <td>
                                                        <span className="heading remark-text" title={remark?.remark}>{remark?.remark}</span>&nbsp;
                                                        <span className="sectiondiv remark-by">By {remark?.user_details?.added_by_name}</span>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                    {remarks?.length > remarksToShow?.length ? <div className='read-more-option' onClick={e => showMoreLessRemarks("more")}><i className='ic-add'></i> Read More</div>: <div className='read-more-option' onClick={e => showMoreLessRemarks("less")}><i className='ic-remove'></i> Read Less</div>}
                                </div> : ''}                                
                                

                                <br></br>
                                <div className="btn-save-remarks">
                                    {dateSelected ? <span className="span-disabled">Save</span> :
                                    <button type="submit" className="btn-primary" onClick={handleSubmit} disabled={dateSelected}>Save</button>
                                    }
                                    <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                                        <span className="m-xs-l">({(remarksHistory && (remarksHistory.length || 0))})</span>
                                    </button>
                                 </div>
                            </Formsy>
                        </div>
                    </div>
                </div >
            </div>
            <div className="view-timeline-popup">
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory  showremarkform={showremarkform} sectionInfo={sectionInfo}/>
                    </Modal>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default DealerFinancierPaymentExchange;