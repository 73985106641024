import React, { useEffect, useMemo, useState } from 'react';
import PaymentVerificationForm from './PaymentVerificationForm'
import { useDispatch, useSelector } from 'react-redux';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, TT_PAYMENT_LABEL, TT_PAYMENT_SORT_ORDER } from '../../config/constants';
import { Nav, Tab } from 'react-bootstrap'
import { useOutletContext, useParams } from 'react-router-dom';
import DocumentGallery from './DocumentGallery';
import crypto from '../../config/crypto';
import TitleTransferPayments from './TitleTransferPayments';
import Loader from '../elements/Loader';
import { getLoanDetail } from '../../store/action/allAction';
import { toast } from "react-toastify";
import { GET_BANK_LIST } from '../../services/masterdata.gql';

const PaymentVerification = () => {
    const user_information = useSelector((state) => state.user.user_information);
    const userInfo = JSON.parse(user_information);
    const [selectedTab, setSelectedTab] = useState("first");
    const [numPayments, setNumPayments] = useState(0);
    const [ttPaymentDetails, setTtPaymentDetails] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [disabledbutton,setDisabledbutton]=useState(false);
    const [loading, setLoading] = useState(false);
    const { lead, leadDetail } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      leadDetail: lead?.leadDetail,
      remarksHistory:lead.remarksHistory || []
    }));
    let params = useParams();
    let lead_id = crypto.decode(params.lead_id)
    const client = useApolloClient();
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    let accessCondition = !IS_USER_ACCESS_PAGE("payment_verification", "payment_verification")?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const isADPCase = useMemo(() => {
      return leadDetail?.lead_computation_details?.adp_type === "Yes"; 
    }, [leadDetail?.lead_computation_details?.adp_type]);

    useEffect(() => {
      if(leadDetail?.title_payment && bankList?.length){
        let ttData = leadDetail?.title_payment?.filter(payment => payment?.status !== "2")?.map((data, index) => {
          return {
          ...data,
          "bank_name": bankList?.find(bank => bank?.label === data?.bank_name.replace(/&amp/g, '&'))?.value || 0,
          "label": TT_PAYMENT_LABEL[data?.payment_type] || "",
          "index": index,
          "is_edit":false,
          "is_save": 1,
          "status":"1",
          "is_tranche_marked": true
        }}).sort((a, b)=> TT_PAYMENT_SORT_ORDER[a?.payment_type] - TT_PAYMENT_SORT_ORDER[b?.payment_type]);
        setTtPaymentDetails(ttData)
        setNumPayments(ttData?.length)
      }
    }, [bankList, leadDetail?.title_payment]);

    useEffect(() => {
      const fetchData = async () => {
          await getBankList();
      };
      fetchData();
    }, []);

    const getBankList = async() => {
      setLoading(true);
          await executeGraphQLQuery(GET_BANK_LIST(),client).then((response) => {
              if (response?.data?.bank_list) {
                setBankList(response?.data?.bank_list);
              }
          }).catch((error) => {
              console.log(error);
          });
      setLoading(false);
    }

    const handleAddPayments = () => {
      let payment_num = ttPaymentDetails?.length ? ttPaymentDetails?.filter(data => data?.status === '1')?.length+1 : numPayments+1;
      setNumPayments(payment_num);
      let tt_payments = ttPaymentDetails;
      tt_payments.push({
        "id": 0,
        "label": "Title Transfer Payment "+payment_num,
        "payment_type":"title_transfer_payment"+payment_num,
        "bank_name" : null,
        "branch_name" : null,
        "account_name" : null,
        "account_number" : null,
        "amount" : 0,
        "is_edit":true,
        "is_save": 0,
        "status":"1",
        "is_tranche_marked": false,
        "index": ttPaymentDetails?.length
      })
      setTtPaymentDetails(tt_payments);
    };

    const handleEditChange = (index) => {
        let updatedttPayment = [...ttPaymentDetails];
        index = updatedttPayment.findIndex(obj => +obj?.index === +index)
        updatedttPayment[index] = {...updatedttPayment[index], "is_edit": !updatedttPayment[index]["is_edit"]}
        setTtPaymentDetails(updatedttPayment);
    }

    const handleDeleteChange = async(index) => {
        let updatedttPayment = [...ttPaymentDetails], count = 0;
        index = updatedttPayment.findIndex(obj => +obj?.index === +index);
        if(updatedttPayment[index]['is_save'] === 1)
          updatedttPayment[index] = {...updatedttPayment[index], "is_save":0, "payment_type": null, "status": "2"}
        else
          updatedttPayment = updatedttPayment.filter((obj, i) => i !== index);
        updatedttPayment = updatedttPayment?.map((payment, i) => {
          if(["generated"].includes(payment.tranche_status) || !payment?.tranche_status){
            if(payment?.status === "1") count++;
            let obj = payment?.status === "1" ? {
              ...payment,
              index : i,
              label: "Title Transfer Payment "+count,
              payment_type : "title_transfer_payment"+count
            } : { ...payment, index : i }
            return obj;
          }else{
            return { ...payment, index : i }
          }
        })
        setTtPaymentDetails(updatedttPayment);
        if(numPayments-1 >= 0) setNumPayments(numPayments-1);
        else setNumPayments(0);
        if(updatedttPayment[index]?.id) await deleteTranche(updatedttPayment[index]);
    }

    const handleInputChange = (target_id, event, type, index) => {
      let value = type === "number-format" ? event.floatValue : type === 'dropdown' ? event.value : event.target.value;
      if(value && value !== undefined){
        let updatedTTPayment = [...ttPaymentDetails];
        index = updatedTTPayment?.findIndex(obj => +obj?.index === +index);
        updatedTTPayment[index] = {
          ...updatedTTPayment[index],
          [target_id]: value,
          "is_save": 0
        }
        setTtPaymentDetails(updatedTTPayment);
      } 
    }

    const deleteTranche = async(trancheToDelete) => {
      setLoading(true);
      let titleTransferInput = [{
        "id": trancheToDelete?.id || 0,
        "lead_id": Number(lead_id),
        "payment_type": trancheToDelete?.payment_type || "",
        "bank_name" : bankList?.find(bank => bank.value === trancheToDelete?.bank_name)?.label || "",
        "branch_name" : trancheToDelete?.branch_name || "",
        "account_name" : trancheToDelete?.account_name || "",
        "account_number" : trancheToDelete?.account_number ? String(trancheToDelete?.account_number) : "",
        "amount" : trancheToDelete?.amount || 0,
        "is_tranche_marked": trancheToDelete?.is_tranche_marked,
        "status" : trancheToDelete?.status,
        "requested_by_name": userInfo?.name || "",
        "created_by": userInfo?.id || 0,
        "updated_by": userInfo?.id || 0
      }];
      //if user has selected some tranches then only proceed ahead
      if(titleTransferInput?.length){
        let finalResponse = {
          "lead_id": Number(lead_id),
          "title_payment": titleTransferInput || [],
          "created_by": userInfo?.id || 0
        }
        let variables = {
          titleTransferInput : finalResponse
        } 
        let mutation = gql`mutation saveTitleTransferDetails($titleTransferInput: TitleTransferPaymentInput!) {
          title_transfer_save(titleTransferInput: $titleTransferInput) {
            success
          }
        }`;

        try {
          await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(lead_id, client));
            toast.success("Tranche Deleted Successfully!");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Please select a Payment Tranche!")
      }
    }

    const handleSubmit = async() => {
      setLoading(true);
      let titleTransferInput = ttPaymentDetails?.filter(data => (!data?.tranche_status || (data?.tranche_status && !['requested', 'paid']?.includes(data?.tranche_status))))?.map(payment => {return {
        "id": payment?.id || 0,
        "lead_id": Number(lead_id),
        "payment_type":payment?.payment_type || "",
        "bank_name" : bankList?.find(bank => bank.value === payment?.bank_name)?.label || "",
        "branch_name" : payment?.branch_name || "",
        "account_name" : payment?.account_name || "",
        "account_number" : payment?.account_number ? String(payment?.account_number) : "",
        "amount" : payment?.amount || 0,
        "is_tranche_marked": payment?.is_tranche_marked,
        "status" : payment?.status,
        "requested_by_name": userInfo?.name || "",
        "created_by": userInfo?.id || 0,
        "updated_by": userInfo?.id || 0
      }})
      //if user has selected some tranches then only proceed ahead
      if(titleTransferInput?.length){
        let finalResponse = {
          "lead_id": Number(lead_id),
          "title_payment": titleTransferInput || [],
          "created_by": userInfo?.id || 0
        }
        let condition = !leadDetail?.title_payment?.length || (leadDetail?.title_payment?.length && !ttPaymentDetails?.filter(data => data.is_save === 1)?.length && !leadDetail?.title_payment?.filter(data => data.tranche_status === "on_hold")?.length) || (leadDetail?.title_payment?.length && !leadDetail?.title_payment?.filter(data => data.tranche_status)?.length);
        let variables = condition ? {
          titleTransferInput : finalResponse
        } : {
          sendTitleTrancheInput : finalResponse
        }
        let mutation = condition ? gql`mutation saveTitleTransferDetails($titleTransferInput: TitleTransferPaymentInput!) {
          title_transfer_save(titleTransferInput: $titleTransferInput) {
            success
          }
        }`: gql`
        mutation send_title_tranche($sendTitleTrancheInput:TitleTransferPaymentInput!) {
          send_title_tranche(sendTitleTrancheInput: $sendTitleTrancheInput) {
            success
          }
        }`;

        try {
          await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(lead_id, client));
            toast.success("Success");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Please select a Payment Tranche!")
      }
    }

  return (
    <div className="image-form-outer">
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
      <DocumentGallery documentCategoryList={documentCategoryList}  />
      </div>
      <div className="form-tab-right-panel ">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading title-transfer-heading">
            <h2>Payment Verification</h2>
                {selectedTab === 'second' && 
                <button type="button" class="btn-line" onClick={handleAddPayments} disabled={getContext.markAsFreezed || accessCondition || numPayments === 2 || leadDetail?.split_payment?.filter(split => ["requested", "paid"].includes(split.tranche_status) && ["split_dealer", "split_dealer2"].includes(split.payment_type))?.length}>
                  <i className='ic-add m-sm-r'></i> Add Payment
                </button>}
          </div>
              <div className="doument-gallery-tabs">
                  <Tab.Container id="left-tabs-example" activeKey={selectedTab} defaultActiveKey="first" onSelect={(e) => setSelectedTab(e)}>
                      <Nav variant="pills" className="flex-column">
                          <div className="tab-list">
                              <Nav.Item>
                                  <Nav.Link eventKey="first">Dealer Payments</Nav.Link>
                              </Nav.Item>
                              <Nav.Item >
                                  <Nav.Link disabled={accessCondition || leadDetail?.lead_computation_details?.title_transfer_type === "No"} eventKey="second">Title transfer Payments</Nav.Link>
                              </Nav.Item>
                          </div>
                      </Nav>
                      <Tab.Content>
                          <Tab.Pane eventKey="first">
                                <PaymentVerificationForm />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                                <TitleTransferPayments bankList={bankList} numPayments={numPayments} ttPaymentDetails={ttPaymentDetails} disabledbutton={disabledbutton} isADPCase={isADPCase} setDisabledbutton={setDisabledbutton} handleEditChange={handleEditChange} handleDeleteChange={handleDeleteChange} setTtPaymentDetails={setTtPaymentDetails} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                          </Tab.Pane>
                      </Tab.Content>
                  </Tab.Container>
              </div>
          
        </div>
      </div>
    </div>
  );
}
export default PaymentVerification;