import React, { useEffect, useState,memo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import ListGridView from "../leads/docs-upload/DocumentViewGallery"
import { useOutletContext } from 'react-router-dom';
import { PAYMENT_PROOF_DOCS} from '../../../src/config/constants';
import ViberAiChats from './ViberAiChats';

const DocumentGallery = (props) => {
    const [docCategoryList, setDocCategoryList] = useState(props.documentCategoryList);
    const [documentList, setDocumentList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedTab, setSelectedTab] = useState('first_submit');
    const [seletedTabDocs, setSelectedTabDocs] = useState([]);
    const [filterCategoryChildList, setFilterCategoryChildList] = useState([])
    const defaultCategory = { label: "Primary Govt ID", value: 1 };
    const defaultSubCategory = { label: "Driver's License", value: 7 };

    const { leadStatusHistory, leadDetail, leadDocs } = useSelector(({ lead, user }) => ({
        leadDocs: (lead && (lead.leadDetail && (lead.leadDetail.leaddocs || []))),
        userInfo: user.user_information || {},
        leadDetail: lead.leadDetail,
        leadStatusHistory: (lead.leadDetail && (lead.leadDetail.lead_status_history || []))
    }));

    const context = useOutletContext();
    const markAsFreezed = context.markAsFreezed  ;
    const showViberChat = context.showViberChat;

    const user_information = localStorage.getItem('user_information');
    let authUser=(user_information && JSON.parse(user_information));
    let subStatusMarkedTillnow = (leadStatusHistory && leadStatusHistory.length) ? leadStatusHistory.map(v=>v.sub_status_id) : [];

    const [docGallery, setDocGallery] = useState({
        selectedCategory: { ...defaultCategory },
        selectedSubCategory: { ...defaultSubCategory },
        filterFiles: [],
        filterCategory: docCategoryList
    });

    useEffect(() => {
        let docCategoryList = props.documentCategoryList || [];
        setDocumentList(leadDocs);
        setDefaultImageCategory();
        let category = docCategoryList.filter(f => f.parent_id === 0 && f.tab_name === selectedTab && f.id !== 111).map(e => ({ label: e.doclang.name, value: e.id }))
        let sub_category = docCategoryList.filter(f => f.id === defaultCategory.value)?.map(v => v.child)[0] || []
        let seletedTabDocIds = sub_category.filter(f => f.tab_name === selectedTab).map(e => e.id);
        sub_category = sub_category && sub_category.length && sub_category.map(e => ({ label: e.doclang.name, value: e.id }));
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.tab_name === selectedTab).forEach(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists);
        seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        let selectedTabDocs = leadDocs.reduce((res, data) => {          
            if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
            return res;
        }, []);
        setDocCategoryList(docCategoryList);
        setCategoryList(category);
        setSubCategoryList(sub_category);
        setSelectedTabDocs(selectedTabDocs);
    }, [props]);

    const setDefaultImageCategory = () => {
        /*  Set default image on load */
        let filtredFiles = leadDocs.reduce((res, data) => {
            if (data.doc_id === defaultSubCategory.value) res.push(data);
            return res;
        }, []);
        setDocGallery({ ...docGallery, filterFiles: filtredFiles, selectedCategory: { ...defaultCategory }, selectedSubCategory: { ...defaultSubCategory } });
    };

    const handleSelectTab = (eventKey) => {
        setSelectedTab(eventKey);
        let docCategoryList = props.documentCategoryList || [];
        let category = docCategoryList.filter(f => f.parent_id === 0 && f.tab_name === eventKey && f.id !== 111).map(e => ({ label: e.doclang.name, value: e.id }));
        setCategoryList(category);
        setSubCategoryList([]);
        let filterCategoryChildLists = [];
        docCategoryList && docCategoryList.filter(f => f.tab_name === eventKey).forEach(data => {
            filterCategoryChildLists = [...filterCategoryChildLists, ...data.child]
        });
        setFilterCategoryChildList(filterCategoryChildLists)

        let seletedTabDocIds = filterCategoryChildLists.map(e => e.id);
        let selectedTabDocs = leadDocs.reduce((res, data) => {
            if (seletedTabDocIds.indexOf(data.doc_id) > -1) res.push(data);
            return res;
        }, []);
        setDocGallery({ ...docGallery, selectedCategory: {}, selectedSubCategory: {} });
        setSelectedTabDocs(selectedTabDocs);
    }
    let isPaymentProofPresent = leadDocs?.filter(docs => [PAYMENT_PROOF_DOCS['MAIN_DEALER'],PAYMENT_PROOF_DOCS['SPLIT_DEALER_1'],PAYMENT_PROOF_DOCS['SPLIT_DEALER_2']].includes(+docs.doc_id))?.length;

    return (
        <div className="doument-gallery-tabs" key={selectedTab}>
            {showViberChat ? <ViberAiChats loan_id={leadDetail?.id} /> :
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'first_submit'} eventKey="first_submit" disabled={false}>First Submit</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'pre-disbursal'} eventKey="pre-disbursal" disabled={!subStatusMarkedTillnow.includes(3)}>Pre Disbursal</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'car_transfer'} eventKey="car_transfer" disabled={!subStatusMarkedTillnow.includes(12)}>Car Transfer</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'post_disbursal'} eventKey="post_disbursal" disabled={!isPaymentProofPresent}>Post Disbursal</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>

                <Tab.Content>
                    <Tab.Pane active={selectedTab} activeTab={selectedTab} eventKey={selectedTab}>
                        <ListGridView
                            docGallery={docGallery}
                            categoryList={categoryList}
                            subCategoryList={subCategoryList}
                            docCategoryList={docCategoryList}
                            filterCategoryChildList={filterCategoryChildList}
                            documentList={documentList}
                            seletedTabDocs={seletedTabDocs}
                            selectedTab={selectedTab}
                            authUser={authUser}
                            setDefaultImageCategory={setDefaultImageCategory}
                            setSubCategoryList={setSubCategoryList}
                            setDocGallery={setDocGallery}
                            key={selectedTab}
                            leadDetail={leadDetail}
                            markAsFreezed={markAsFreezed}
                        />
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>}
        </div>
    )
}

export default memo(DocumentGallery);