import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {  useDispatch } from 'react-redux';
import Loader from '../../elements/Loader';
import {  Dropdown } from 'react-bootstrap';
import noImage from "../../../webroot/images/noimage.svg";
import GallaryCarousel from "./GallaryCarousal";
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import DocumentHistory from "./DocumentHistory";
import { LEAD_DOCS_REMARKS_QUERY } from '../../../services/leadDocsRemarks.gql';
import { executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import ReSubmissionDocument from "./ReSubmissionDocument";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, STATUS_CODES, SUB_STATUS_CODES} from '../../../../src/config/constants';
import LeadHelper from "../LeadHelper";

function UploadedTagDocs(props) {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [UploadedFiles] = useState(props.UploadedFiles);
    const [filterFiles, setFilterFileList] = useState([]);
    const [filter_doc_list, setFilterDocList] = useState(props.filter_doc_list);
    const [filter_doc_child_list, setFilterDocChildList] = useState(props.filter_doc_child_list)
    const [isLoading, setIsLoading] = useState(true);
    const [currentFileId, setFileId] = useState(0);
    const [currentFileDocId, setFileDocID] = useState(0);
    const [currentFileParentId, setFileParentID] = useState(0);
    const [filterData, setFilterData] = useState(props.filterData);
    const [enableRemoveTag, setRemoveTag] = useState(false);
    const [documentHistoryList, setDocumentHistoryList] = useState([]);
    const [showDocumentHistoryModal, setShowDocumentHistoryModal] = useState(false);
    const [showResubmisisonModal, setShowResubmissionModal] = useState(false);
    const [docActionType, setDocActionType] = useState('');
    const [leadDetail] = useState(props.leadDetail);
    const adp_type = leadDetail?.lead_computation_details?.adp_type === "Yes";
    let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    let { docList } = props;

    useEffect(()=>{
        setFilterDocList(props.filter_doc_list);
        setFilterDocChildList(props.filter_doc_child_list)
    },[props.filter_doc_list,props.filter_doc_child_list])

    useEffect(() => {
        // console.log("Use One")
        if(props.filterData.file_id || props.filterData.doc_id){
            let singleimage = props.filterData.file_id ?
            props.filterFiles.filter(v => v.id === props.filterData.file_id) : props.filterFiles.filter(v => v.doc_id === props.filterData.doc_id);
            if(singleimage.length){
            setFileId(singleimage?.[0]?.id);
            if(singleimage?.[0]?.doc_id){
            setRemoveTag(true);
            setFileDocID(singleimage?.[0]?.doc_id);
            }
            else{
                setRemoveTag(false);
                setFileDocID(0);
            }
            if (singleimage[0].parent_doc_id)
            setFileParentID(singleimage?.[0].parent_doc_id)
            else setFileParentID(0)

            let newimageArray = [...singleimage]
                props.filterFiles.forEach(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                })
                setFilterFileList(newimageArray);
            }else{
                setFilterFileList(props.filterFiles);
            }
        }
        else
        setFilterFileList(props.filterFiles);
    }, [props.filterFiles,props.filterData.doc_id,props.filterData.file_id ]);

    useEffect(() => {
        setIsLoading(false);
        if (filterFiles && filterFiles.length) {
          
            setFileId(filterFiles[0].id);
            if (filterFiles[0]?.doc_id) {
                setRemoveTag(true);
                setFileDocID(filterFiles[0]?.doc_id);
            } else {
                setFileDocID(0);
            }
            if (filterFiles[0].parent_doc_id) {
                setFileParentID(filterFiles[0].parent_doc_id)
            } else {
                setFileParentID(0)
            }  
        } else {
            
            setFileId(0);
            setRemoveTag(false);
            setFileDocID(0);
            setFileParentID(0)
        }
    }, [filterFiles])

    useEffect(() => {
        // console.log("Use Three",props.filterData)
        let filterFilesOrg = props.filterFiles || [];
        setFilterData(props.filterData);
        if (props.filterData.doc_id) {
            setIsLoading(false);
            let filterFilesnew = filterFilesOrg.filter(v => v.doc_id === props.filterData.doc_id);
            setFilterFileList(filterFilesnew);
        } else {
            if (props.filterData.file_id) {
                let singleimage = filterFilesOrg.filter(v => v.id === props.filterData.file_id);
                setFileId(props.filterData.file_id);
                setRemoveTag(false);
                let newimageArray = [...singleimage]
                filterFilesOrg.forEach(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                })
                setFilterFileList(newimageArray);
            }
        }
    }, [props.filterData,props.filterFiles]);

    const getFileId = (file_id, doc_id, parent_id) => {
        // console.log(file_id,"get file")
        if (file_id !== currentFileId) {
            setFileId(file_id);
            setFileDocID(doc_id);
            setFileParentID(parent_id);
        }
        if (doc_id) {
            setRemoveTag(true);
        } else {
            setRemoveTag(false);
        }

    }

    const tagDoc = (doc) => {
        if (currentFileId) {
            let confirm = window.confirm('Do you want to tag this file ?');
            if (confirm) {
                props.tagDoc(doc, currentFileId, filter_doc_list);
            }
        } else {
            toast.error("Invalid file for tagged");
        }
    }
    const removeTag = () => {
        if (currentFileId) {
            // console.log(currentFileId,"remove tag")
            let confirm = window.confirm('Are you sure you want to remove tag ?');
            if (confirm) {
                props.removeTag(current_file, currentFileId, filter_doc_list);
                setFilterData({ doc_id: 0 })
                setRemoveTag(false);
            }
        } else {
            toast.error("Invalid file");
        }
    }


    const markAsApproved = () => {
        let data = {
            lead_id: leadDetail.id,
            user_id: props.user_id || 0,
            doc_status: "2",
            doc_source: "web",
            remark:'',
            update_action_type: 'documents',
            lead_docs: [{
                id: currentFileId,
                doc_id: currentFileDocId,
                parent_doc_id: currentFileParentId
            }]
        };
        let message = "Doc Approved Successfully";
        manageDocAction(data, message);
    }

    /** will handle for both resubmission request as well as approval */
    const manageDocAction = async (leadData, message=null) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            setIsLoading(true);
            let variables = { approvedDocInput: leadData };
            const mutation = gql`
                mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                    approved_document(approvedDocInput: $approvedDocInput) {
                    lead_id
                    }
                }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result && result.data) {
                    if (message) toast.success(message);
                    if (result.data) {
                        dispatch(getLoanDetail(leadDetail.id, client))
                        props.setFilterData({doc_id: leadData?.lead_docs?.[0]?.doc_id,file_id: leadData?.lead_docs?.[0]?.id})
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }).catch(error => {
                toast.error('Docs already approved/requested. Please refresh the page and check');
                setIsLoading(false);
            });
        } else {
            toast.error("No image found.");
        }
    }

    const getDocumentHistoryList = async (doc_id, lead_id) => {
        if (doc_id) {
            executeGraphQLQuery(LEAD_DOCS_REMARKS_QUERY(lead_id, doc_id), client).then((result) => {
                setDocumentHistoryList(result.data.doc_remark_history);
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        }
    }

    const showDocumentHistory = () => {
        setShowDocumentHistoryModal(true);
        document.body.classList.add("overflow-hidden");
        getDocumentHistoryList(currentFileDocId, leadDetail.id);
    }

    const hideModalDocumentHistory = () => {
        setShowDocumentHistoryModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showResubmission = (action_type) => {
        setShowResubmissionModal(true);
        setDocActionType(action_type);
        document.body.classList.add("overflow-hidden");
    }

    const hideResubmissionModal = () => {
        setShowResubmissionModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    let current_file = currentFileId && filterFiles && filterFiles.length? filterFiles.find(file => file.id === currentFileId) : null;
    // let { temp, docList } = LeadHelper.getFilterDocumentList(UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail);
    useEffect(() => {
        if (docList?.length) {
            setFilterDocList(docList)
        }
    }, [docList]);

    let doc_list_arr = props?.temp.map((doc, index) => {
        var childCountArray = doc.child.filter(val => val.count > 0);
        var childRequiredCount = doc.child.filter(val => (val.count >= val.min_upload && val.is_required === '1') || (val.count > 0 && val.count >= val.min_upload));
        var childCountRequired = (doc.child.length) ? doc.child.filter(v => (v.is_required === '1') || (v.count > 0)) : [];
        let docUploadStatus = ((doc.child.length === 0 && doc.count >= doc.min_upload) || (doc.child.length > 0 && childRequiredCount.length === childCountRequired.length && childCountArray.length > 0)
        ) ? 'done' : '';

        return (
            <div className="taging-list-drop-down" key={doc.id}>
                <ul>
                    <li>
                        {`${doc.doclang && doc.doclang.name} ${doc.is_required === '1' ? ' *' : ''}`}
                        {
                            (docUploadStatus === 'done') ? (<span className="stepprogress"><i className="ic-check"></i></span>) : null
                        }
                        <ul>
                            {
                                (doc.child.length) ?

                                    doc.child.map((doc, index) => {

                                        return (<li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)} >{`${doc.doclang.name} ${doc.financier_id ? " | "+doc.financier_name : ""} ${(doc.is_required === '1') ? '*' : ''}`} <span className="doc_count">{doc.count} /{doc.max_upload}</span>
                                        </li>)
                                    })
                                    : <li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)}>{`${doc.doclang.name} ${(doc.is_required === '1') ? '*' : ''}`}
                                        <span className="doc_count">{doc.count} /{doc.max_upload}</span>
                                    </li>
                            }
                        </ul>
                    </li>
                </ul>
            </div>
        )
    })


    let mainCatName = '', subCatName = '', doc_id = (filterData && filterData.doc_id) ? filterData.doc_id : currentFileDocId;

    let AppliedDocs = (doc_id) ? filter_doc_child_list.filter(v => v.id === doc_id) : [];

    subCatName = (AppliedDocs && AppliedDocs.length) ? AppliedDocs[0].doclang.name || "" : "";
    if (AppliedDocs && AppliedDocs.length && AppliedDocs[0].parent_id) {
        let AppliedDocsParent = filter_doc_child_list.filter(v => v.id === AppliedDocs[0].parent_id);
        mainCatName = (AppliedDocsParent && AppliedDocsParent.length) ? AppliedDocsParent[0].doclang.name || "" : "";
    }

    let status_historyci=leadDetail && leadDetail.lead_status_history.filter((v)=>v.sub_status_id===9);
    const disableTagDropdown = 
    accessCondition || 
    (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status) && props.activeTab !== "pre-disbursal") || 
    (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status) && props.activeTab === "pre-disbursal" && leadDetail?.lead_status_history.filter((v)=>v.sub_status_id===9)?.length) || 
    (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status) && props.activeTab === 'car_transfer' && adp_type &&  leadDetail?.lead_computation_details?.vt_sub_status_id === SUB_STATUS_CODES.ORCR_VERIFIED && status_historyci)

    return (
        <div className="tag-image-bx" key={`${filterFiles.length}${filterData.doc_id}`}>
            <div className={`${(mainCatName || subCatName) ? "image-tagning-drop-down tag-category-sub-category" : "image-tagning-drop-down mark-tag-txt"}`}>
                {
                    (isLoading) ? (<Loader />) : null
                }
                {(
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={disableTagDropdown}>

                            <div className="select-category">
                                {
                                    (mainCatName || subCatName) ? (<span className="tag-main-category">
                                        {(mainCatName || subCatName) ? mainCatName : ''}
                                    </span>) : "Mark as a Tag"
                                }

                                {
                                    (subCatName) ? subCatName : ""
                                }

                            </div>


                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                doc_list_arr.length ? doc_list_arr : null
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>            
            {
                (enableRemoveTag && ((current_file && ([0, 2].includes(current_file.doc_status) === false)) || (props.activeTab === "pre-disbursal" && !leadDetail?.lead_status_history.filter((v)=>v.sub_status_id===9)?.length))) ? (<div className="remove-tag-txt" onClick={removeTag}>
                    Remove Tag
                </div>) : null
            }
            <div className="doument-view-list-gallery">
                {
                    filterFiles && filterFiles.length ?
                        <div className="verfication-img">
                            <GallaryCarousel isLoading={isLoading} slides={filterFiles} document_list={filter_doc_list} getFileId={getFileId} filter_doc_child_list={filter_doc_child_list} />
                        </div>
                        :
                        <div className="noImgTagging">
                            <div className="verfication-img-no-image">
                                <img src={noImage} className="" alt="" />
                                <span className="no-img-txt">No Document Attached</span>
                            </div>
                        </div>
                }

                <div className="image-opt doc-image-opt">
                    {/* Checking for non Pre-disbursal doc */}
                    {(!["pre-disbursal","car_transfer","post_disbursal"].includes(props.activeTab)) && (
                        <div className="btn-submission-approved">
                            {enableRemoveTag && ![1,29,37].includes(leadDetail.sub_status_id) && (
                                <>
                                    <button className="btn-line" disabled={accessCondition} onClick={showDocumentHistory}>View History</button>
                                    {
                                        current_file && +current_file.doc_status === 2 ? <><button className="btn-line btn-approve"><i className="ic-check"></i>Approved</button></>
                                            : current_file && +current_file.doc_status === 0 ? <><button className="btn-fill" disabled={accessCondition}> <i className="ic-check"></i>Re-submission Requested</button></> 
                                                : (props.activeTab === 'first_submit' && ![3,5].includes(leadDetail?.status_id) && !leadDetail.lead_status_history.find((history) => history.sub_status_id === 3)) ? <><button className="btn-line" onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                                    <button className="btn-line" disabled={accessCondition} onClick={markAsApproved}>Mark as Approved</button></>
                                                    : <button className="btn-line" disabled={accessCondition} onClick={markAsApproved}>Mark as Approved</button>
                                    }
                                </>
                            )}
                            {!filterFiles.length && ![3,5].includes(leadDetail?.status_id) && ![1,29,37].includes(leadDetail.sub_status_id) && !leadDetail.lead_status_history.find((history) => history.sub_status_id === 3) && props.activeTab === 'first_submit' && (
                                <>
                                    <button className="btn-line"  onClick={showDocumentHistory}>View History</button>
                                    <button className="btn-line" onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
                    <DocumentHistory documentHistoryList={documentHistoryList} documentHistoryModelShowFlag={showDocumentHistoryModal} hideModalDocumentHistory={hideModalDocumentHistory} />
                </div>
                <ReSubmissionDocument leadId={leadDetail.id} documentId={currentFileDocId} documentParentId={currentFileParentId} filterData={filterData} actionType={docActionType} showResubmissionModal={showResubmisisonModal} hideModalReSubmission={hideResubmissionModal} requestResubmission={manageDocAction} user_id={props.user_id} />
            </div>

        </div>
    )
}
export default UploadedTagDocs;