import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useSelector } from 'react-redux';
import FormFields from "./formFields/createLead.json";
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { NAME_WITH_LATIN_CHARACTER_REGEX} from '../../config/constants';
import { Nav, Tab } from 'react-bootstrap';
import { GET_ALL_DEALER_LIST, GET_INVENTORY_LIST, GET_ROLES_WITH_USERS } from '../../services/config.gql';
import crypto from "../../config/crypto";
import Select from 'react-select';
import { CREATE_LEAD_QUERY } from '../../services/leads.gql';
import Loader from "../elements/Loader";


const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");

const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const CreateLeadForm = () => {
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    const [basicDetails, setBasicDetails] = useState({ "lead_source": "dealer", "created_by": user_id });
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [activeTab, setActiveTab] = useState('dealer');
    const [dealershipName, setDealershipName] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [userList, setUserList] = useState([]);
	const [loading, setLoading] = useState(false);

    const client = useApolloClient();
    let navigate = useNavigate();

    useEffect(() => {
        let role_id = activeTab === "dealer" ? "6" : "8";
        getUserList(role_id);
    }, [activeTab]);

    const getInventoryList=(dealer_id)=>{
        executeGraphQLQuery(GET_INVENTORY_LIST(dealer_id), client).then((result) => {
            setInventoryList(result?.data?.get_inventory_list?.inventory_list);
        })
        .catch((errors) => {
            toast.error("Somethig went wrong ,please try again")
        });
    }

    const getDealerList=(userId=0)=>{
        setLoading(true);
        executeGraphQLQuery(GET_ALL_DEALER_LIST(1, userId), client).then((result) => {
            let dealer_list = result?.data?.get_all_dealer_list?.dealer_list?.filter(data => ![null, ""].includes(data?.label));
            setLoading(false);            
            setDealershipName(dealer_list);
        })
        .catch((errors) => {
            setLoading(false);
            toast.error("Somethig went wrong ,please try again")
        });
    }

    const getUserList = async (role_id) => {
        setLoading(true);
        let user_list = [],userlist=[];
        await executeGraphQLQuery(GET_ROLES_WITH_USERS,client).then((response) => {
            if (response && response.data && response.data.get_roles_with_users) {
                user_list = response.data.get_roles_with_users.find(obj => obj.role_id === role_id)?.users || [];
                user_list && user_list.map((v)=>{
                    userlist.push({value:v.id,label:v.name})
                    return v;
                })
                setLoading(false);
                setUserList(userlist);
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });   
    };

    const onTabChange = async(tab, event) => {
        if (event.type === "click") {
            setActiveTab(tab);
            setBasicDetails(currentValue => ({
                ...currentValue,
                "lead_source": tab
            }))
        }
    }

    const handleInputChange = event => {
        if (event.target.id) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value!== undefined && field_name) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: dateFormat(date,"dd mmm, yyyy")
            }))
        }
    }

    const handleChange = (field_name, data) => {
        if(field_name === "inventory_id"){
            let inventory_obj = inventoryList?.find(list => list?.value === data?.value);
            setBasicDetails(currentValue => ({
                ...currentValue,
                "inventory_id": inventory_obj?.value,
                "dealer_car_id": inventory_obj?.value,
                "make_year": inventory_obj?.make_year,
                "make_id": inventory_obj?.make_id,
                "model_id": inventory_obj?.model_id,
                "variant_id": inventory_obj?.version_id,
                "make_name": inventory_obj?.make_name,
                "model_name": inventory_obj?.model_name,
                "variant_name": inventory_obj?.version_name,
                "color": inventory_obj?.color
            }))
        } else if(field_name === "dealer_id"){
            let mapped_dealer_id = dealershipName?.find(dealer_list => dealer_list?.value === data?.value)?.mapped_dealer_id;
            setBasicDetails(currentValue => ({
                ...currentValue,
                "inventory_id": 0,
                "dealer_car_id": null,
                "make_year": null,
                "make_id": 0,
                "model_id": 0,
                "variant_id": 0,
                "make_name": '',
                "model_name": '',
                "variant_name": '',
                "color": '',
                "is_dealer_unmapped": mapped_dealer_id !== "" ? "0" : "1",
                "lead_source": mapped_dealer_id !== "" ? "dealer": "agent",
                [field_name]: data?.value
            }))
            getInventoryList(data?.value);
        } else{
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: data?.value
            }))
        }
        if(["so_id", "agent_id"].includes(field_name))
            getDealerList(data?.value);
    }

    const handleSubmit = () => {
      if(!allowSubmit) return;
      setLoading(true);
      let createLeadBasicInput = {...basicDetails};
      let variables = { user_id: parseInt(basicDetails?.so_id) || parseInt(basicDetails?.agent_id), role_id: activeTab === "dealer" ? 6 : 8, api_called_by: "web" };
      createLeadBasicInput['created_by'] = user_id;
       delete createLeadBasicInput?.inventory_id;
       delete createLeadBasicInput?.so_id;
       if(createLeadBasicInput?.agent_id) delete createLeadBasicInput?.agent_id;
       let mutation = CREATE_LEAD_QUERY; 
       variables = { ...variables, createLeadBasicInput: createLeadBasicInput };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.createLeadBasic || null;
        if (!response) {
          setLoading(false);
          throw new Error(response.error || "Something went wrong");
        }else{
            setBasicDetails(currentValue => ({
                ...currentValue,
                "id": response?.id
            }))
        }
        setLoading(false);
        toast.success("UCF Application Created Successfully! Application id is "+ response?.lead_code);
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.message);
      })
    }

    let BasicDetailsForm = useMemo(() => {
      return FORM_FIELDS.map(field => {
        if (field.name === "dob") {
            field["maxDate"] = DOB_MAX_DATE;
            field["minDate"] = DOB_MIN_DATE;
        }
        return field;
      })
    }, []);

    const nameWithLatinCharacterValidation = (rule, value) => {
      return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
    }

    return (
      <div className="bg-white p-lg-t" key={"create_lead"}>
        
          <div className="container">
            <div className="lead-form-heading applicatiom-method-heading">
                <h3>Add Used Car Finance Application</h3>
                <h2>Application Method</h2>
            </div>
        <div className="lead-detail-tabs add-user-applicatiom-method">
           
                <Tab.Container id="left-tabs-example" activeKey={activeTab}
                    onSelect={(tab, e) => onTabChange(tab, e)}
                    defaultActiveKey="finance-0">
                    <Nav variant="pills" className="flex-column nav nav-pills">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link eventKey={"dealer"}>Sales Officer</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"agent"}>Agent</Nav.Link>
                            </Nav.Item>                    
                        </div>                    
                    </Nav>
                </Tab.Container>
            
            </div>

            <div className='row'>
            {
                activeTab === "dealer" ?
                <div className='col-md-3'>
                <fieldset className="single-select">
                    <div className="material">
                    <Select
                        value={basicDetails?.['so_id'] ? userList.filter(({ value }) => value === basicDetails['so_id']) : ""}
                        onChange={(e) => handleChange('so_id', e)}
                        options={userList || []}
                        placeholder={"Select Sales Officer"}
                        name="so_id"
                        id="so_id"
                        aria-label="so name"
                        disabled={basicDetails?.id}
                    />
                    </div>
                </fieldset>
                </div> : 
                <div className='col-md-3'>
                <fieldset className="single-select">
                    <div className="material">
                    <Select
                        value={basicDetails?.['agent_id'] ? userList.filter(({ value }) => value === basicDetails['agent_id']) : ""}
                        onChange={(e) => handleChange('agent_id', e)}
                        options={userList || []}
                        placeholder={"Select Agent"}
                        name="agent_id"
                        id="agent_id"
                        aria-label="agent name"
                        disabled={basicDetails?.id}
                    />
                    </div>
                </fieldset>
                </div>
            }
                <div className='col-md-3'>
                <fieldset className="single-select">
                    <div className="material">
                    <Select
                        value={basicDetails?.['dealer_id'] ? dealershipName.filter(({ value }) => value === basicDetails['dealer_id']) : ""}
                        onChange={(e) => handleChange('dealer_id', e)}
                        options={dealershipName || []}
                        placeholder={"Select Dealer"}
                        name="dealer_id"
                        id="dealer_id"
                        aria-label="dealer name"
                        disabled={basicDetails?.id}
                    />
                    </div>
                </fieldset>
                </div>
                <div className='col-md-3'>
                <fieldset className="single-select">
                    <div className="material">
                    <Select
                        value={basicDetails?.['inventory_id'] ? inventoryList.filter(({ value }) => value === basicDetails['inventory_id']) : ""}
                        onChange={(e) => handleChange('inventory_id', e)}
                        options={inventoryList || []}
                        placeholder={"Select Inventory"}
                        name="inventory_id"
                        id="inventory_id"
                        aria-label="inventory name"
                        disabled={basicDetails?.id}
                    />
                    </div>
                </fieldset>
                </div>
            </div>

            <div className="lead-form-heading p-lg-t">
              <span className="main-heading">Basic Details</span>
            </div>
            <Formsy
              className=""
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field basic"
            >
              <div className="row">

                {BasicDetailsForm.map((field) => (
                    ["text", "pattern-format"].includes(field.type) && ((activeTab === "dealer" && ((basicDetails?.is_dealer_unmapped === "1") || ((basicDetails?.is_dealer_unmapped === "0" || !basicDetails?.is_dealer_unmapped) && !['agent_name', 'agent_mobile']?.includes(field?.name)))) || (activeTab === "agent" && !['agent_name', 'agent_mobile']?.includes(field?.name))) ? (
                  <fieldset class="form-filed col-md-3 p-lg-t">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={basicDetails && basicDetails[field.name]}
                        placeholder=" "
                        label={field.label}
                        onChange={
                        field.type === "pattern-format" 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={ field.name === 'name' && { nameWithLatinCharacterValidation } || field.validations}
                        validationError={
                        basicDetails[field.name] ? field.validationError : ""
                        }
                        readOnly={field.readOnly || basicDetails?.id}
                        required={field.required}
                        disabled={basicDetails?.id}
                        showAsterisk={field.showAsterisk}
                    />
                  </fieldset>
                    ) : 
                    field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-3 p-lg-t createlead-datepicker">
                      <FormsyDatePicker
                          name="dob"
                          selectedDate={(basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])) || ""}
                          placeholder={field.label}
                          maxDate={field.maxDate}
                          value={(basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])) || ""}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                              basicDetails[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          showAsterisk={field.showAsterisk}
                          hasError={(basicDetails.hasOwnProperty(field.name) && !basicDetails[field.name]) ? true : false}
                          readOnly={field?.readOnly || basicDetails?.id}
                          disabled={basicDetails?.id}
                      />
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

            {
                !basicDetails?.id ?
              <div className="btn-save-remarks t-center btn-bottom">
                <button aria-label="save naxt" type="submit" className="btn-primary" disabled={!allowSubmit}>
                  Submit
                </button>
              </div> : 
              <div className="btn-save-remarks t-center btn-bottom">
                <button aria-label="save naxt" type="button" className="btn-line m-sm-r" onClick={() =>{navigate(`/dashboard`)}}>
                    Go to Home
                </button>
                <button aria-label="save naxt" type="button" className="btn-primary" onClick={() =>{navigate(`/lead-detail/customer-details/basic-details/${crypto.encode(basicDetails?.id)}`)}}>
                    View Loan ID
                </button>
              </div>
            }   
            </Formsy>
			{loading ? <Loader /> : null}

          </div>
        </div>
    
    );
}
export default CreateLeadForm;