import { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import UploadDocumentView from './UploadDocumentView'
// import App from '../../../component/FileUpload'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAYMENT_PROOF_DOCS} from '../../../../src/config/constants';

function DocumentUpload(props) {
    // let history = useHistory();
    let params = useParams();
    const [activeTab, setActiveTab] = useState('first_submit');

    const { leadStatusHistory,leadDocs } = useSelector(({ lead, user }) => ({
        leadDocs: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
        authUser: user.user_information || {},
        leadStatusHistory: lead.leadDetail && (lead.leadDetail.lead_status_history || []),
    }));

    let subStatusMarkedTillnow = (leadStatusHistory && leadStatusHistory.length) ? leadStatusHistory.map(v => v.sub_status_id) : [];
    let isPaymentProofPresent = leadDocs?.filter(docs => [PAYMENT_PROOF_DOCS['MAIN_DEALER'],PAYMENT_PROOF_DOCS['SPLIT_DEALER_1'],PAYMENT_PROOF_DOCS['SPLIT_DEALER_2'], PAYMENT_PROOF_DOCS.PROOF_OF_VERIFICATION].includes(+docs.doc_id))?.length;
    useEffect(() => {
        setActiveTab('first_submit');
    }, [params])

    const handleSelectTab = (eventKey) => {
        setActiveTab(eventKey);
        //if (history) history.push(`/lead-detail/doc/${eventKey}`);
    }

    return (
        <div className="document-upload-tabs" key={activeTab}>
            <div className="doument-upload-left-panel">
                <div className="heading-txt">
                    <h2>Upload Documents</h2>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} onSelect={handleSelectTab}>
                    <Nav variant="pills" className="flex-column tab-line">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'first_submit'} eventKey="first_submit" disabled={false}>First Submit</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'pre-disbursal'} eventKey="pre-disbursal" disabled={!subStatusMarkedTillnow.includes(3)}>Pre Disbursal</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'car_transfer'} eventKey="car_transfer" disabled={!subStatusMarkedTillnow.includes(12)}>Car Transfer</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'post_disbursal'} eventKey="post_disbursal" disabled={!isPaymentProofPresent}>Post Disbursal</Nav.Link>
                            </Nav.Item>
                        </div>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane active={activeTab} eventKey={activeTab}>
                            <div className="file-upload-bx">
                                {/* <App /> */}
                                <UploadDocumentView activeDocTab={'upload'} activeTab={activeTab} key={activeTab} docCategoryList={props.docCategoryList} markAsFreezed={props.markAsFreezed} />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}
export default DocumentUpload;