import { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import RefinanceCarDetails from "./CarDetails";
import RefinanceLoanDetails from "./LoanDetails";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../services/refinance/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { SAVE_FINANCIER_LOAN_APPROVAL } from "../../../services/refinance/refinance-financier.gql";
import Loader from "../../elements/Loader";
import GeneralService from "../../../services/generalService";
import { GET_MMV_ALL } from "../../../services/refinance/leads.gql";
import DocumentGallery from "../DocsUpload/DocumentGallery";
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF, getSavedDocuments } from "../../../store/action/allAction";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from "../../../config/constants";
import LeadRemarkHistory from "../lead/component/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { CONTRACT_SIGNED, LOST_STATUS } from "../../../config/refinance/constant";
import { PH_REFINANCE_BUCKET_NAME } from "../../../config/refinance_constant";

/**
 * Recursively removes '__typename' key from all objects and arrays
 * @param {*} data - The input data (object, array, or primitive)
 * @returns {*} - The data with all '__typename' keys removed
 */
const removeTypename = (data) => {
    if (Array.isArray(data)) {
        return data.map((item) => removeTypename(item));
    } else if (data !== null && typeof data === "object") {
        const newObj = {};
        for (const [key, value] of Object.entries(data)) {
            if (key !== "__typename") {
                newObj[key] = removeTypename(value);
            }
        }
        return newObj;
    }
    return data;
};

function populateMap(data, keyField) {
    const newMap = new Map();
    for (const item of data || []) {
        const key = String(item[keyField]);
        if (!newMap.has(key)) {
            newMap.set(key, []);
        }
        newMap.get(key).push(item);
    }
    for (const items of newMap.values()) {
        items.push({ id: "0", value: "0", label: "Other" });
    }
    return newMap;
}

const ALL_OPTIONS = [];
const DEPENDENCIES = {
    vehicle_usage: ["vehicle_type", "make_id", "model_id", "variant_id", "make_year"],
    vehicle_type: ["make_id", "model_id", "variant_id", "make_year"],
    make_id: ["model_id", "variant_id", "make_year"],
    model_id: ["variant_id", "make_year"],
    variant_id: ["make_year"],
};
const MAX_UPLOADS = 5;

const RefinanceFinancierLoanApproval = (props) => {
    let { lead_id } = useParams();
    const navigate = useNavigate();
    const client = useApolloClient();
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadDetail: lead?.ucrfLeadDetail || {},
    }));
    const getContext = useOutletContext();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;
    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    const dispatch = useDispatch();
    const formRefs = useRef({});
    const carDetailFromRef = useRef();
    const [loading, setLoading] = useState(false);
    const [carDetails, setCarDetails] = useState({});
    const [loanDetails, setLoanDetails] = useState(new Map());
    const [makeMap, setMakeMap] = useState(new Map());
    const [modelMap, setModelMap] = useState(new Map());
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [versionOptions, setVersionOptions] = useState([]);
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const [activeTab, setActiveTab] = useState("car_details");
    const [userEditAccess, setUserEditAccess] = useState(true);
    const [basicDetails, setBasicDetails] = useState({});
    const [activeFinancierId, setActiveFinancierId] = useState(null);
    const [financierDetailsFilled, setFinancierDetailsFilled] = useState(false);

    const documentCategoryList = getContext.docList || [];

    const getMmvAll = async (category_id) => {
        setLoading(true);
        try {
            const result = await executeGraphQLQuery(GET_MMV_ALL(category_id), client);
            const mmvData = result?.data?.getMmvAll;

            if (!mmvData) {
                throw new Error("No MMV data received");
            }

            const newCategoryMap = populateMap(mmvData.make ?? [], "category_id");
            const newMakeMap = populateMap(mmvData.model ?? [], "mk_id");
            const newModelMap = populateMap(mmvData.version ?? [], "p_m_id");
            setMakeMap(newMakeMap);
            setModelMap(newModelMap);

            ["vehicle_usage", "vehicle_type", "make_year"].forEach((key) => {
                ALL_OPTIONS[key] = master_data_ucrf[key] ?? [];
            });

            const initialCarDetails = Object.keys(carDetails).length ? carDetails : leadDetail?.financier_loan_approval?.car_details;
            if (!initialCarDetails) {
                return null;
            }

            const usage = initialCarDetails.vehicle_usage;
            const usageId = ALL_OPTIONS.vehicle_usage.find((opt) => opt.value === usage)?.id;
            const updatedVehicleTypeOptions = usageId ? ALL_OPTIONS.vehicle_type.filter((type) => type.vehicle_usage === parseInt(usageId)) : [];
            setVehicleTypeOptions(updatedVehicleTypeOptions);

            const newCategoryId = category_id === null ? "1" : String(category_id);
            const updatedMakeOptions = Object.values(newCategoryMap.get(newCategoryId) ?? []);
            let updatedModelOptions, updatedVersionOptions;
            if (parseInt(initialCarDetails?.make_id) === 0) {
                const otherOptions = [{ id: "0", value: "0", label: "Other" }];
                updatedModelOptions = otherOptions;
                updatedVersionOptions = otherOptions;
            } else {
                updatedModelOptions = Object.values(newMakeMap.get(String(initialCarDetails.make_id)) ?? []);
                updatedVersionOptions = Object.values(newModelMap.get(String(initialCarDetails.model_id)) ?? []);
            }
            setMakeOptions(updatedMakeOptions);
            setModelOptions(updatedModelOptions);
            setVersionOptions(updatedVersionOptions);
            setCarDetails(removeTypename(initialCarDetails));
            return {
                updatedMakeOptions,
                updatedModelOptions,
                updatedVersionOptions,
                updatedVehicleTypeOptions,
            };
        } catch (error) {
            console.error("Error in getMmvAll:", error);
            toast.error("Something went wrong, please try again");
            return null;
        } finally {
            setLoading(false);
        }
    };

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const navigateToNext = () => {
        navigate(`/refinance/lead-detail/contract_signing/${lead_id}`);
    };

    const submitForm = () => {
        if (activeTab === "loan_details") {
            if (!activeFinancierId) {
                toast.error("No financier selected");
            }
            if (formRefs.current[activeFinancierId]) {
                formRefs.current[activeFinancierId].validateForm();
                if (formRefs.current[activeFinancierId].state.isValid) {
                    formRefs.current[activeFinancierId].submit();
                } else {
                    toast.error("Please correct the errors in the loan details form");
                }
            }
        } else if (activeTab === "car_details") {
            if (carDetailFromRef.current) {
                carDetailFromRef.current.validateForm();
                if (carDetailFromRef.current.state.isValid) {
                    carDetailFromRef.current.submit();
                } else {
                    toast.error("Please correct the errors in the car details form form");
                }
            }
        }
    };

    const handleTabChange = (event, tabName) => {
        setActiveTab(tabName);
        if (tabName === "loan_details" && loanDetails.size > 0) {
            setActiveFinancierId(Array.from(loanDetails.keys())[0]);
        }
    };

    const handleFinancierTabChange = (e, financierId) => {
        setActiveFinancierId(financierId);
        let tempFinancierDetailsFilled = loanDetails.get(financierId)?.is_financier_loan_approval_filled;
        setFinancierDetailsFilled(tempFinancierDetailsFilled === 1);
    };

    const handleCarDetailChange = async (event, fieldName, fieldType) => {
        let tempCarDetails = { ...carDetails };
        let tempMakeOptions = [...makeOptions];
        let tempModelOptions = [...modelOptions];
        let tempVersionOptions = [...versionOptions];
        let tempVehicleTypeOptions = [...vehicleTypeOptions];

        if (fieldType === "select" && DEPENDENCIES[fieldName]) {
            DEPENDENCIES[fieldName].forEach((field) => {
                tempCarDetails[field] = "";
            });
        }

        const nameFields = { make_id: "make_name", model_id: "model_name", variant_id: "variant_name" };
        if (nameFields[fieldName]) {
            tempCarDetails[nameFields[fieldName]] = event.label;
        }

        tempCarDetails[fieldName] = fieldType === "select" ? event?.value ?? "" : event?.target?.value ?? "";
        switch (fieldName) {
            case "vehicle_usage":
                const usageId = ALL_OPTIONS.vehicle_usage.find((opt) => opt.value === tempCarDetails.vehicle_usage)?.id;
                tempVehicleTypeOptions = usageId ? ALL_OPTIONS.vehicle_type.filter((type) => type.vehicle_usage === parseInt(usageId)) : [];
                if (!tempVehicleTypeOptions.some((opt) => opt.value === tempCarDetails.vehicle_type)) {
                    tempCarDetails.vehicle_type = "";
                    tempCarDetails.vehicle_category = "";
                }
                break;

            case "vehicle_type":
                tempCarDetails["vehicle_category"] = event?.vehicle_category;
                const allOptions = await getMmvAll(event?.vehicle_category);
                tempMakeOptions = allOptions.updatedMakeOptions;
                tempModelOptions = [];
                tempVersionOptions = [];
                break;

            case "make_id":
                if (event?.value === "0") {
                    tempCarDetails["model_id"] = "0";
                    tempCarDetails["variant_id"] = "0";
                    tempModelOptions = [{ id: "0", value: "0", label: "Other" }];
                    tempVersionOptions = [{ id: "0", value: "0", label: "Other" }];
                } else {
                    const modelData = makeMap.get(String(event?.id ?? "")) ?? {};
                    tempModelOptions = [...Object.values(modelData)];
                    tempVersionOptions = [];
                }
                break;

            case "model_id":
                if (tempCarDetails["model_id"] === "0") {
                    tempCarDetails["variant_id"] = "0";
                    tempVersionOptions = [{ id: "0", value: "0", label: "Other" }];
                } else {
                    const versionData = modelMap.get(String(event?.id ?? "")) ?? {};
                    tempVersionOptions = [...Object.values(versionData)];
                }
                break;
        }

        setMakeOptions(tempMakeOptions);
        setModelOptions(tempModelOptions);
        setVersionOptions(tempVersionOptions);
        setVehicleTypeOptions(tempVehicleTypeOptions);
        setCarDetails(tempCarDetails);
    };

    const handleLoanDetailChange = async (event, financierId, parentFieldName, childFieldName, fieldType) => {
        // Persist the event
        if (event.persist) event.persist();

        let tempMap = new Map(JSON.parse(JSON.stringify(Array.from(loanDetails))));
        let financierData = tempMap.get(financierId);
        if (!financierData) {
            financierData = {};
        }
        if (!financierData[parentFieldName]) {
            financierData[parentFieldName] = {};
        }

        // Update the specific field
        let newValue;
        if (fieldType === "file_upload") {
            newValue = await handleFileChange(event, financierData?.[parentFieldName]?.[childFieldName] || []);
        } else if (fieldType === "number-format" && event?.value !== undefined) {
            newValue = event.value;
        } else if (["input", "radio", "textarea"].includes(fieldType)) {
            newValue = event.target.value;
        } else if (["date"].includes(fieldType)) {
            newValue = event;
        }

        // Only update if we have a valid new value
        if (newValue !== undefined && newValue !== null) {
            financierData[parentFieldName][childFieldName] = newValue;

            // Helper function to check if a value is a valid number (whole number or float)
            const isValidNumber = (value) => {
                if (typeof value !== "string") return false;
                const trimmedValue = value.trim();
                return /^-?\d+(\.\d+)?$/.test(trimmedValue) && isFinite(parseFloat(trimmedValue));
            };

            // Helper function to safely parse a number
            const safeParseFloat = (value) => (isValidNumber(value) ? parseFloat(value) : 0);

            // Calculate total additional deductions
            const additionalDeductions = Object.entries(financierData.additional_deductions || {})
                .filter(([key]) => key !== "total_additional_deductions")
                .reduce((sum, [_, value]) => sum + safeParseFloat(value), 0);
            financierData.additional_deductions.total_additional_deductions = additionalDeductions.toString();

            // Calculate total standard deductions
            const standardDeductions = Object.entries(financierData.standard_deductions || {})
                .filter(([key]) => key !== "total_standard_deductions")
                .reduce((sum, [_, value]) => sum + safeParseFloat(value), 0);
            financierData.standard_deductions.total_standard_deductions = standardDeductions.toString();

            // Calculate total deductions
            const totalDeductions = additionalDeductions + standardDeductions;
            financierData.loan_calculations = financierData.loan_calculations || {};
            financierData.loan_calculations.total_deductions = totalDeductions.toString();

            // Calculate Net Proceeds
            const totalLoanAmount = safeParseFloat(financierData.loan_details?.total_loan_amount);
            const rebate = safeParseFloat(financierData.loan_calculations?.rebate);
            const netProceeds = totalLoanAmount - rebate - totalDeductions;
            financierData.loan_calculations.net_proceeds = netProceeds.toString();

            tempMap.set(financierId, financierData);
            setLoanDetails(tempMap);
        }
    };

    const handleFileChange = async (event, attachments) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            toast.error("Please select a file to upload.");
            return;
        }
        if (attachments.length >= MAX_UPLOADS) {
            toast.error(`Maximum ${MAX_UPLOADS} files can be uploaded.`);
            return;
        }
        const formData = new FormData();
        formData.append("upload_type", PH_REFINANCE_BUCKET_NAME);
        formData.append("lead_id", leadDetail.lead_id);
        formData.append("images", selectedFile);
        try {
            const response = await GeneralService.uploadFilesToS3(formData, { "Content-Type": "multipart/form-data" });
            const doc_path = response?.data?.data?.[0]?.file_url || "";
            if (doc_path) {
                return [...attachments, doc_path];
            } else {
                return [];
            }
        } catch (error) {
            toast.error("An error occurred while uploading the file.");
        }
    };

    const handleSave = () => {
        setLoading(true);
        const lead_id = leadDetail.lead_id || "";
        const variables = {
            flaInput: {
                lead_id: leadDetail.lead_id,
                detail_type: activeTab,
            },
        };

        if (activeTab === "car_details") {
            const preparedCarDetails = {
                vehicle_usage: carDetails.vehicle_usage,
                vehicle_type: carDetails.vehicle_type,
                vehicle_category: carDetails.vehicle_category.toString(),
                make_id: carDetails.make_id.toString(),
                make_name: carDetails.make_name,
                model_id: carDetails.model_id.toString(),
                model_name: carDetails.model_name,
                variant_name: carDetails.variant_name,
                variant_id: carDetails.variant_id.toString(),
                make_year: carDetails.make_year.toString(),
                vehicle_plate_number: carDetails.vehicle_plate_number,
            };
            variables.flaInput.car_details = preparedCarDetails;
        } else if (activeTab === "loan_details") {
            if (!activeFinancierId) {
                toast.error("No financier selected");
                setLoading(false);
                return;
            }
            const selectedFinancierDetails = loanDetails.get(activeFinancierId);
            if (!selectedFinancierDetails) {
                toast.error("Selected financier details not found");
                setLoading(false);
                return;
            }

            const isLoanApproved = selectedFinancierDetails.additional_loan_info?.loan_approved === "yes";

            let preparedLoanDetails = {
                financier_id: activeFinancierId,
                additional_loan_info: selectedFinancierDetails.additional_loan_info,
            };

            if (isLoanApproved) {
                preparedLoanDetails = {
                    ...preparedLoanDetails,
                    loan_details: {
                        ...selectedFinancierDetails.loan_details,
                        term_in_months: parseInt(selectedFinancierDetails.loan_details?.term_in_months),
                    },
                    standard_deductions: selectedFinancierDetails.standard_deductions,
                    additional_deductions: selectedFinancierDetails.additional_deductions,
                    loan_calculations: selectedFinancierDetails.loan_calculations,
                };
            }

            variables.flaInput.loan_details = [preparedLoanDetails];
        }

        executeGraphQLMutation(SAVE_FINANCIER_LOAN_APPROVAL, variables, client)
            .then((response, error) => {
                if (response?.data) {
                    dispatch(getUCRFLeadDetail(lead_id, client));
                    dispatch(getUCRFFormFillStatus({ lead_id }, client));
                    dispatch(getSavedDocuments(lead_id, client));
                    if(response.data.saveFinancerLoanApproval.is_valid){
                        toast.success(response.data.saveFinancerLoanApproval.message);
                        if(activeTab == "car_details"){
                            let element = document.getElementById("loan_details")
                            if(element?.children?.length){
                                element.children[0].click()
                            }
                        }
                    } else {
                        toast.error(response.data.saveFinancerLoanApproval.message);
                    }
                }
            })
            .catch((error) => {
                toast.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setUserEditAccess(IS_USER_ACCESS_PAGE("refinanceFinancierLoanApproval", "ucrf-financier-loan-approval")?.is_edit_access);
        getMmvAll(leadDetail?.financier_loan_approval?.car_details?.vehicle_category || null);
        const loanDetails = leadDetail?.financier_loan_approval?.loan_details;
        if (loanDetails) {
            const tempMap = new Map();
            (removeTypename(loanDetails) ?? []).forEach((data) => {
                const tempData = JSON.parse(JSON.stringify(data));
                const [financierObject] = master_data_ucrf?.financier_list?.filter((item) => item.id === data.financier_id) ?? [];
                tempData.financier_name = financierObject?.financier_name ?? "";
                tempMap.set(data.financier_id, tempData);
            });
            if (tempMap.size > 0) {
                const firstFinancierId = tempMap.keys().next().value;
                let tempFinancierDetailsFilled = tempMap.get(firstFinancierId)?.is_financier_loan_approval_filled;
                setFinancierDetailsFilled(tempFinancierDetailsFilled === 1);
                setActiveFinancierId(firstFinancierId);
            }
            setLoanDetails(tempMap);
        }
        let basicDetails = { ...leadDetail.basic_details };
        setBasicDetails(basicDetails);
        dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: "WEB" }, client));
    }, [leadDetail]);

    // const isSubmitDisabled = useMemo(() => {
    //     return basicDetails.refinance_sub_status === "10" ? true : false;
    // }, [basicDetails]);

    const sectionInfo = { section: "Financier Loan Approval", sub_section: "Financier Loan Approval" };
    let markAsFreezed =
        leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex((e) => e == CONTRACT_SIGNED) > -1 ? true : false;
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    return (
        <div className="image-form-outer">
            {loading ? <Loader /> : ""}
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer financer-loan-approve-outer">
                    <div className={" switch-toggle"}>
                        <div className="lead-form-heading">
                            <span className="main-heading">Financier Loan Approval</span>
                            <h2>Financier Loan Approval</h2>
                        </div>
                        <div className="">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="car_details">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="car_details"
                                                onClick={(e) => {
                                                    handleTabChange(e, "car_details");
                                                }}
                                            >
                                                Car Details
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item id="loan_details">
                                            <Nav.Link eventKey="loan_details" onClick={(e) => handleTabChange(e, "loan_details")}>
                                                Loan Details
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="car_details">
                                        <div className="p-lg-t">
                                            <RefinanceCarDetails
                                                carDetails={carDetails}
                                                allOptions={ALL_OPTIONS}
                                                handleChange={handleCarDetailChange}
                                                vehicleTypeOptions={vehicleTypeOptions}
                                                makeOptions={makeOptions}
                                                modelOptions={modelOptions}
                                                versionOptions={versionOptions}
                                                markAsFreezed={markAsFreezed}
                                                leadLost={leadLost}
                                                carDetailFromRef={carDetailFromRef}
                                                handleSave={handleSave}
                                            />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="loan_details">
                                        <div className="financer-loan-tab-outer">
                                            <RefinanceLoanDetails
                                                loanDetails={loanDetails}
                                                handleChange={handleLoanDetailChange}
                                                handleSave={handleSave}
                                                formRefs={formRefs}
                                                markAsFreezed={markAsFreezed}
                                                activeFinancierId={activeFinancierId}
                                                handleFinancierTabChange={handleFinancierTabChange}
                                            />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                    <div className="btn-save-remarks">
                        {!userEditAccess ||
                        CHECK_ASSIGN_USER(leadDetail?.assigned_to) ||
                        markAsFreezed ||
                        leadLost ||
                        (activeTab === "loan_details" && financierDetailsFilled) ? (
                            <span className="span-disabled">Save</span>
                        ) : (
                            <button
                                type="submit"
                                className="btn-primary"
                                disabled={
                                    !userEditAccess ||
                                    CHECK_ASSIGN_USER(leadDetail?.assigned_to) ||
                                    markAsFreezed ||
                                    leadLost ||
                                    (activeTab === "loan_details" && financierDetailsFilled)
                                }
                                onClick={submitForm}
                            >
                                Save & Next
                            </button>
                        )}
                        {activeTab === "loan_details" && (
                            <>
                                {!userEditAccess ||
                                CHECK_ASSIGN_USER(leadDetail?.assigned_to) ||
                                markAsFreezed ||
                                leadLost ||
                                (activeTab === "loan_details" && !financierDetailsFilled) ? (
                                    <span className="span-disabled">Next</span>
                                ) : (
                                    <button
                                        type="submit"
                                        className="btn-primary"
                                        disabled={
                                            !userEditAccess ||
                                            CHECK_ASSIGN_USER(leadDetail?.assigned_to) ||
                                            markAsFreezed ||
                                            leadLost ||
                                            (activeTab === "loan_details" && !financierDetailsFilled)
                                        }
                                        onClick={() => {
                                            navigateToNext();
                                        }}
                                    >
                                        Next
                                    </button>
                                )}
                            </>
                        )}
                        <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezedNewFlow}
                        accessCondition={accessCondition}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default RefinanceFinancierLoanApproval;
