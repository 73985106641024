import React,{useState,useEffect, lazy, Suspense} from 'react';
import { BrowserRouter,Routes ,Route } from 'react-router-dom';
import Header from "../common/Header"
import Footer from '../common/Footer'
import Loader from '../view/elements/Loader';
// import LeadList from "../view/lead-list/LeadList";
// import LeadDetail from "../view/leads/LeadDetail";
// import RuleEngine from "../view/rule-engine/RuleEngine"
// import Dashboard from "../view/dashboard/Dashboard"
// import FinancerLead from "../view/financier/Financer"
// import Login from "../view/login/Login"
// import SentToFinancerConfigListing from "../view/config/SentToFinancerConfigListing";
// import SentToFinancerGroupDetail from "../view/config/SentToFinancerGroupDetail";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BasicDetailsForm from '../view/leads/customer-details/BasicDetails';
import SendFinancer from '../view/leads/SendFinancer';
import VehicleDetailAndSoftCredit from '../view/leads/VehicleDetailAndSoftCredit';
import AdditionalDetails from '../view/leads/customer-details/AdditionalDetails';
import EmploymentDetails from '../view/leads/customer-details/EmploymentDetails';
import BankDetails from '../view/leads/customer-details/BankDetails';
import ResidenceDetails from '../view/leads/customer-details/ResidentialDetails';
import CharacterReference from '../view/leads/customer-details/CharacterReference';
import CBBasicDetails from '../view/leads/co-borrower-details/CB_BasicDetails';
import CBAdditionalDetails from '../view/leads/co-borrower-details/CB_AdditionalDetails';
import CBEmploymentDetails from '../view/leads/co-borrower-details/CB_EmploymentDetails';
import CBBankDetails from '../view/leads/co-borrower-details/CB_BankDetails';
import CBResidentialDetails from '../view/leads/co-borrower-details/CB_ResidentialDetails';
import Computation from '../view/leads/Computation';
import DealerFinancierPaymentExchange from '../view/leads/DealerFinancierPaymentExchange';

import CreditInvestigation from '../view/leads/CreditInvestigation';
import OrcrSubmission from '../view/leads/OrcrSubmission';
import PaymentVerification from '../view/leads/PaymentVerification';
import LoanExpected from '../view/leads/LoanExpected';
import DocumentUpload from '../view/leads/docs-upload/DocumentUpload';
import CreateLeadForm from '../view/leads/CreateLead.js';

import RefinanceBasicDetailsForm from '../view/refinance/customer-details/BasicDetails.js';
import RefinancePersonalDetailsForm from '../view/refinance/customer-details/PersonalDetails.js';
import RefinanceResidentialDetailsForm from '../view/refinance/customer-details/ResidentialDetails.js';
import RefinanceEmployeeDetailsForm from '../view/refinance/customer-details/EmployeeDetails.js';
import RefinanceCoPersonalDetailsForm from '../view/refinance/co-customer-details/CoPersonalDetails.js';
import RefinanceCoResidentialDetailsForm from '../view/refinance/co-customer-details/CoResidentialDetails.js';
import RefinanceCoEmployeeDetailsForm from '../view/refinance/co-customer-details/CoEmployeeDetails.js';
import RefinanceSelectFinancierForm from '../view/refinance/soft-approval/SelectFinancier.js';
import RefinanceSoftApprovalForm from '../view/refinance/soft-approval/SoftApprovalStatus.js';
import RefinanceCIScheduleForm from '../view/refinance/lead_process/CISchedule.js';
import RefinanceCIReportForm from '../view/refinance/lead_process/CIReport.js';
import RefinanceContractSigningForm from '../view/refinance/lead_process/ContractSigning.js';
import RefinanceLoanDisbursementForm from '../view/refinance/lead_process/LoanDisbursement.js';
import RefinanceFinancierLoanApproval from '../view/refinance/financier-loan-approval/FinancierLoanApproval.js';
import RefinanceComputationGenerated from '../view/refinance/computation-generated/ComputationGenerated.js';
import RefinancePaymentVerification from '../view/refinance/computation-generated/PaymentVerification.js';
import RefinanceIncentivePaid from '../view/refinance/computation-generated/IncentivePaid.js';
import RefinanceCreateLead from '../view/refinance/create-refinance-lead/CreateRefinanceLead.js';
import RefinanceIncentiveReceived from '../view/refinance/computation-generated/IncentiveReceived.js';
 
// import ManageRoleTab from '../view/manage-role/ManageRoleTab';
import UCRFDocumentUpload from '../view/refinance/DocsUpload/DocumentUpload.js';
import RefinanceLoanExpected from '../view/refinance/LoanExpected/LoanExpected.js';

// Component with Lazy Loading
const Login = lazy(() => import("../view/login/Login"));
const FinancerLead = lazy(() => import("../view/financier/Financer"));
const ManageRoleTab = lazy(() => import("../view/manage-role/ManageRoleTab"));
const LeadList = lazy(() => import("../view/lead-list/LeadList"));
const LeadDetail = lazy(() => import("../view/leads/LeadDetail"));
const RuleEngine = lazy(() => import("../view/rule-engine/RuleEngine"));
const Dashboard = lazy(() => import("../view/dashboard/Dashboard"));
const SentToFinancerConfigListing = lazy(() => import("../view/config/SentToFinancerConfigListing"));
const SentToFinancerGroupDetail = lazy(() => import("../view/config/SentToFinancerGroupDetail"));
const NotFound = lazy(() => import("../view/not-found/NotFound"));
const IFLeadList = lazy(() => import('../view/inventory-funding/lead/LeadList'));
const IFLeadDetail = lazy(() => import("../view/inventory-funding/lead-details/LeadDetails"));
const UpdateFinancierList = lazy(() => import('../view/inventory-funding/update-financier-list/LeadList.js'));
const IFLoanManagement = lazy(() => import('../view/inventory-funding/loan-management/lead/LeadList'));
const IFLoanManagementDetails = lazy(() => import('../view/inventory-funding/loan-management/lead-detail/LeadDetails'));


const Thanks = lazy(() => import('../view/inventory-funding/Thanks'));
const BPKPUpdate = lazy(() => import('../view/inventory-funding/bpkp/BPKPUpdate'));
const BPKList = lazy(() => import('../view/inventory-funding/bpkp/BPKList'));
const IFBPKList = lazy(() => import('../view/inventory-funding/bpkp/IFBPKList'));
const DealerNotification = lazy(() => import('../view/inventory-funding/DealerNotification'));
const FinancierMRP = lazy(() => import('../view/inventory-funding/ph-calculator/FinancierMRP'));

const RefinanceLeadList = lazy(() => import('../view/refinance/lead/component/LeadList.js'))
const RefinanceSentToFinancier = lazy(() => import('../view/refinance/lead-details/SendFinancer.js'))
const UCRFLeadDetail = lazy(() => import("../view/refinance/LeadDetail.js"));
const SentToFinancerConfigListingUCRF = lazy(() => import("../view/refinance/config/SentToFinancerConfigListing"));
const SentToFinancerGroupDetailUCRF = lazy(() => import("../view/refinance/config/SentToFinancerGroupDetail"));

const MainRoute=()=>{

  let leadDetailRoutes = (
    <>
      <Route path="/lead-detail/customer-details/basic-details/:lead_id" element={<BasicDetailsForm/>} />
      <Route path="/lead-detail/customer-details/additional-details/:lead_id" element={<AdditionalDetails/>} />
      <Route path="/lead-detail/customer-details/employment-details/:lead_id" element={<EmploymentDetails/>} />
      <Route path="/lead-detail/customer-details/bank-details/:lead_id" element={<BankDetails/>} />
      <Route path="/lead-detail/customer-details/residential-information/:lead_id" element={<ResidenceDetails />} />
      <Route path="/lead-detail/customer-details/character-ref/:lead_id" element={<CharacterReference />} />

      <Route path="/lead-detail/co-borrower-details/basic-details/:lead_id" element={<CBBasicDetails/>} />
      <Route path="/lead-detail/co-borrower-details/additional-details/:lead_id" element={<CBAdditionalDetails/>} />
      <Route path="/lead-detail/co-borrower-details/employment-details/:lead_id" element={<CBEmploymentDetails/>} />
      <Route path="/lead-detail/co-borrower-details/bank-details/:lead_id" element={<CBBankDetails/>} />
      <Route path="/lead-detail/co-borrower-details/residential-information/:lead_id" element={<CBResidentialDetails />} />

      <Route path="/lead-detail/soft-approval/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"soft_approval"} name={"Soft Approval"}/>} />
      <Route path="/lead-detail/send-financier/:lead_id" element={<SendFinancer />} />
      <Route path="/lead-detail/credit-investigation/:lead_id" element={<CreditInvestigation />} />
      <Route path="/lead-detail/credit-investigation-recieved/:lead_id" element={<VehicleDetailAndSoftCredit  current_page={"credit_investigation_recieved"} name={"Credit Investigation Received"} />} />
      <Route path="/lead-detail/orcr-submission/:lead_id" element={<OrcrSubmission />} />
      
      <Route path="/lead-detail/car-handover/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"car_handover"} name={"Car Handover"}/>}/>
      <Route path="/lead-detail/orcr-recieved/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_received"} name={"ORCR Received From Dealer"}/>} />

      <Route path="/lead-detail/orcr-sent-for-title-transfer/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_sent_for_title_transfer"} name={"ORCR Sent for Title Transfer"}/>}/>
      <Route path="/lead-detail/orcr-recieved-after-title-transfer/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_received_after_title_transfer"} name={"ORCR Received After Title Transfer"}/>} />
      <Route path="/lead-detail/orcr-sent-for-verification/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_sent_for_verification"} name={"ORCR Sent for Verification"}/>}/>
      <Route path="/lead-detail/orcr-verification-status/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_verification_status"} name={"ORCR Verification Status"}/>} />

      <Route path="/lead-detail/orcr-verfied/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"orcr_verified"} name={"ORCR Verified By Financier"}/>} />
      <Route path="/lead-detail/conract-signing-received/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"contract_signed"} name={"Contract Signing Received"}/>} />
      <Route path="/lead-detail/credit-document-received/:lead_id" element={<VehicleDetailAndSoftCredit current_page={"credit_advice_received"} name={"Credit Advice Received"}/>} />

      <Route path="/lead-detail/computation-generated/:lead_id" element={<Computation current_page={'computation_generated'} name={"Computation Generated"}/>} />
      <Route path="/lead-detail/computation-verified/:lead_id" element={<Computation current_page={'computation_verified'} name={"Computation Verified"}/>} />
      <Route path="/lead-detail/computation-regenerated/:lead_id" element={<Computation current_page={'computation_regenerated'} name={"Computation Regenerated"}/>} />
      <Route path="/lead-detail/payment-verification/:lead_id" element={<PaymentVerification />} />

      <Route path="/lead-detail/paid-to-dealer/:lead_id" element={<DealerFinancierPaymentExchange tab={"paid_to_dealer"} name={"Paid To Dealer"}/>} />
      <Route path="/lead-detail/loan-value-received/:lead_id" element={<DealerFinancierPaymentExchange tab={"loan_value_recieved"} name={"Loan Value Recieved"}/>} />
      <Route path="/lead-detail/dealer-incentive-received/:lead_id" element={<DealerFinancierPaymentExchange tab={"dealer_incentive_recieved"} name={"Dealer Incentive Recieved"}/>} />
      <Route path="/lead-detail/main-dealer-payment/:lead_id" element={<DealerFinancierPaymentExchange tab={"main_dealer_payment"} name={"Main Dealer Payment"}/>} />
      <Route path="/lead-detail/onhold-dealer-payment/:lead_id" element={<DealerFinancierPaymentExchange tab={"onhold_dealer_payment"} name={"On Hold Dealer Payment"}/>} />
      <Route path="/lead-detail/split1-dealer-payment/:lead_id" element={<DealerFinancierPaymentExchange tab={"split_dealer1_payment"} name={"Split Dealer 1 Payment"}/>} />
      <Route path="/lead-detail/split2-dealer-payment/:lead_id" element={<DealerFinancierPaymentExchange tab={"split_dealer2_payment"} name={"Split Dealer 2 Payment"}/>} />
      
      <Route path="/lead-detail/loan-value-received/:lead_id" element={<DealerFinancierPaymentExchange tab={"loan_value_recieved"} name={"Loan Value Received"}/>} />
      <Route path="/lead-detail/dealer-incentive-received/:lead_id" element={<DealerFinancierPaymentExchange tab={"dealer_incentive_recieved"} name={"Dealer Incentive Received"}/>} />

      <Route path="/lead-detail/loan-expected/:lead_id" element={<LoanExpected />} />
      <Route path="/lead-detail/docs-upload/:lead_id" element={<DocumentUpload />} />
    </>
  );

  let UCRFDetailRoutes = (
    <>
      <Route path="/refinance/lead-detail/basic-details/:lead_id" element={<RefinanceBasicDetailsForm/>} />
      <Route path="/refinance/lead-detail/select-financier/:lead_id" element={<RefinanceSelectFinancierForm/>} />
      <Route path="/refinance/lead-detail/soft-approval-data/:lead_id" element={<RefinanceSoftApprovalForm/>} />
      <Route path="/refinance/lead-detail/personal-details/:lead_id" element={<RefinancePersonalDetailsForm/>} />
      <Route path="/refinance/lead-detail/residential-details/:lead_id" element={<RefinanceResidentialDetailsForm/>} />
      <Route path="/refinance/lead-detail/employee-details/:lead_id" element={<RefinanceEmployeeDetailsForm/>} />
      <Route path="/refinance/lead-detail/co-personal-details/:lead_id" element={<RefinanceCoPersonalDetailsForm/>} />
      <Route path="/refinance/lead-detail/co-residential-details/:lead_id" element={<RefinanceCoResidentialDetailsForm/>} />
      <Route path="/refinance/lead-detail/co-employee-details/:lead_id" element={<RefinanceCoEmployeeDetailsForm/>} />
      <Route path="/refinance/lead-detail/sent-to-financier/:lead_id" element={<RefinanceSentToFinancier state/>} />
      <Route path="/refinance/lead-detail/ci_schedule/:lead_id" element={<RefinanceCIScheduleForm/>} />
      <Route path="/refinance/lead-detail/financier-loan-approval/:lead_id" element={<RefinanceFinancierLoanApproval/>} />
      <Route path="/refinance/lead-detail/ci_report/:lead_id" element={<RefinanceCIReportForm/>} />
      <Route path="/refinance/lead-detail/contract_signing/:lead_id" element={<RefinanceContractSigningForm/>} />
      <Route path="/refinance/lead-detail/loan_disbursement/:lead_id" element={<RefinanceLoanDisbursementForm/>} />
      <Route path="/refinance/lead-detail/computation-generated/:lead_id" element={<RefinanceComputationGenerated/>} />
      <Route path="/refinance/lead-detail/payment-verification/:lead_id" element={<RefinancePaymentVerification/>} />
      <Route path="/refinance/lead-detail/incentive-paid/:lead_id" element={<RefinanceIncentivePaid/>} />
      <Route path="/refinance/lead-detail/incentive-received/:lead_id" element={<RefinanceIncentiveReceived/>} />
      <Route path="/refinance/lead-detail/docs-upload/:lead_id" element={<UCRFDocumentUpload />} />
      <Route path="/refinance/lead-detail/loan-expected/:lead_id" element={<RefinanceLoanExpected />} />
      {/* <Route path="/refinance/lead-detail/ci_report_check/:lead_id" element={<RefinanceSoftApprovalForm/>} /> */}
    </>
  );
  
  const [logg,setLogg]=useState(false);
  const [isValidRoute,setIsValidRoute]=useState(true);
  useEffect(()=>{
    let checklog=localStorage.getItem("loggedIn");
        if(checklog)
        setLogg(true);
  },[])

  const parentCallback=()=>{
    let checklog=localStorage.getItem("loggedIn");
    if(checklog)
    setLogg(true);
  }
  
  // Function to determine whether to show the header
  const showHeader = () => {
    return setIsValidRoute(false);
  };

    return(
        <BrowserRouter>
        <div>
          { logg && isValidRoute && <Header /> || null }
          {/* <Navigation /> */}
          <ToastContainer />
          <Suspense fallback={<Loader/>}>
          <Routes>
          <Route exact path="/" element={<Login parentCallback={parentCallback} />} />
         {/* <Route path="/login-error" element={<LoginOtp/>} /> */}
            <Route path="/login"  element={<Login parentCallback={parentCallback} />} />
            <Route path="/financer" element={<FinancerLead/>} />
            <Route path="/role-list" element={<ManageRoleTab/>} />
            <Route path="/create-lead" element={<CreateLeadForm/>} />
            <Route path="/lead-list" element={<LeadList/>} />
            <Route path="/lead-list/:params" element={<LeadList/>} />
            <Route path="/lead-list/:status/:searchtype/:searchtext" element={<LeadList/>} />
            <Route path="/lead-detail" element={<LeadDetail/>}>
              {leadDetailRoutes}
            </Route>
            <Route path="/rule-engine" element={<RuleEngine/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path={`/email-template-config/:template_type`} element={<SentToFinancerConfigListing/>} />
            <Route path={`/email-template-config-detail/:financier_id/:main_template_type`} element={<SentToFinancerGroupDetail/>} />  
            <Route path= "*" element={<NotFound showHeader = {showHeader} />} />

            <Route path={`/inventory-funding/lead-list/:status_id`} element={<IFLeadList/>} />
            <Route path={`/inventory-funding/lead-list`} element={<IFLeadList/>} />
            <Route path={`/inventory-funding/lead-details/:loan_id`} element={<IFLeadDetail/>} />
            <Route path={`/inventory-funding/update-financier`} element={<UpdateFinancierList/>} />

            <Route path={`/inventory-funding/loan-management/lead-details/:loan_id`} element={<IFLoanManagementDetails/>} />
            <Route path={`/inventory-funding/loan-management/:dealer_dashboard`} element={<IFLoanManagement/>} />
            <Route path={`/inventory-funding/loan-management`} element={<IFLoanManagement/>} />
            <Route path={`/bpkp-update/:token/:lead_id`} element={<BPKPUpdate/>}/>
            <Route path={`/bpkp-update/:token`} element={<BPKPUpdate/>}/> 
            <Route path={`/bpkp-list/:token`} element={<BPKList/>}/>
            <Route path={`/inventory-funding/bpkp-list`} element={<IFBPKList/>}/>
            <Route path={`/thanks`} element={<Thanks/>} />
            <Route path={`/inventory-funding/dealer-notifications`} element={<DealerNotification/>}/>
            <Route path={`/dif-ph-calculator`} element={<FinancierMRP/>}/>           

            {/* Routes for UCRF */}
            <Route path="/refinance/lead-list" element={<RefinanceLeadList/>} />
            <Route path="/refinance/lead-list/:status" element={<RefinanceLeadList/>} />
            <Route path="/refinance/lead-list/:status/:searchtype/:searchtext" element={<RefinanceLeadList/>} />
            <Route path="/create-refinance-lead" element={<RefinanceCreateLead />} />
            <Route path="/refinance/lead-detail" element={<UCRFLeadDetail/>}>
              {UCRFDetailRoutes}
            </Route>
            <Route path={`/refinance/email-template-config/:template_type`} element={<SentToFinancerConfigListingUCRF/>} />
            <Route path={`/refinance/email-template-config-detail/:financier_id/:main_template_type`} element={<SentToFinancerGroupDetailUCRF/>} />  
            
          </Routes>
          </Suspense>
          { logg && isValidRoute && <Footer /> || null }
        </div>
      </BrowserRouter>
    )
}

export default MainRoute;

